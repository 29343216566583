import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-hide-button',
  templateUrl: './hide-button.component.html',
  styleUrls: ['./hide-button.component.scss']
})
export class HideButtonComponent implements OnInit {

  @Input() backgroundColor = 'transparent';
  @Input() color = 'white';
  constructor() { }

  ngOnInit() {
  }

}

import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {PaginatorComponent} from '../paginator/paginator.component';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import {DataTransferService} from '../../../core/services/data-transfer.service';
import {FormatDateService} from '../../../core/services/format-date.service';
import {FormControl, FormGroup} from '@angular/forms';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-admin-users-tab',
  templateUrl: './admin-users-tab.component.html',
  styleUrls: ['./admin-users-tab.component.scss']
})
export class AdminUsersTabComponent implements OnInit, AfterViewInit {
  @ViewChild(PaginatorComponent, {static: true}) paginator: PaginatorComponent;
  tableColumnsNames = ['refNumber', 'name', 'email', 'phone', 'dateOfBirth', 'dateCreated', 'status'];
  dataSource: MatTableDataSource<any> = null;
  usersQuotesCount: number;
  usersQuotesData: any;
  quoteStatuses = ['NEW REQUEST', 'QUOTE', 'N T U', 'PURCHASED'];
  selectForm: FormGroup;

  constructor(private router: Router,
              private dataTransferService: DataTransferService,
              private formatDateService: FormatDateService,
              private notifService: NotifService) { }

  ngOnInit() {
    this.dataTransferService.getUserQuotesCount().subscribe(
      success => this.usersQuotesCount = success,
      error => this.notifService.error(error.message)
    );
    this.getUserQuotesData(20, 0);
    this.selectForm = new FormGroup({
      selectOption: new FormControl()
    });
  }

  ngAfterViewInit() {
    this.setPaginator();
  }

  getUserQuotesData(pageSize, pageNumber) {
    this.dataTransferService.getUserQuotesData(pageSize, pageNumber).subscribe(usersQuotes => {
      this.usersQuotesData = usersQuotes.map( quoteData => ({
        ...quoteData,
        createdOn: this.formatDateService.changeDateFormat(quoteData.createdOn),
        dateOfBirth: this.formatDateService.changeDateFormat(quoteData.dateOfBirth),
        status: quoteData.status === 'NTU' ? 'N T U' : quoteData.status.replace('_', ' ')
      }));
      this.dataSource = new MatTableDataSource(this.usersQuotesData);
    }, error => this.notifService.error(error.message));
  }

  setPaginator() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterByStatus(event) {
    this.dataSource.filter = event.value.trim().toLowerCase();
  }

  openQuotePage(row) {
    this.router.navigate([`admin/${row.quoteCode}`]);
  }

  pageChanged(event) {
    this.getUserQuotesData(event.pageSize, event.pageIndex);
    this.selectForm.reset();
  }
}

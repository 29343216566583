import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UploadResponse} from 'src/app/model/excel-upload-response.model';
import {formatDate} from '@angular/common';
import { FilesResponse } from 'src/app/model/files-response.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  uploadResponse: UploadResponse;

  constructor(private httpClient: HttpClient) { }

  getFiles(): Observable<FilesResponse[]> {
    return this.httpClient.get<FilesResponse[]>('/file/versions', {});
  }

  fileUpload(data: any): Observable<UploadResponse> {
    const formData: FormData = new FormData();
    formData.append('file', data.fileToUpload);
    formData.append('nextVersionApplianceDate', data.nextVersionApplianceDate);

    return this.httpClient.post<UploadResponse>('/file/import/bulk-data', formData).pipe(
    tap(response => {
      this.uploadResponse = response;
      localStorage.setItem('last-excel-name', this.uploadResponse.name);
      localStorage.setItem('last-excel-date', formatDate(new Date(), 'MM/dd/yyyy', 'en'));
    }));
  }

  fileDownload(data): Observable<any> {
    return this.httpClient.get(`/file/export?filename=${data.filename}&isFutureVersion=${data.isFutureVersion}`, {responseType: 'arraybuffer'});
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remuneration-and-fees',
  templateUrl: './remuneration-and-fees.component.html',
  styleUrls: ['./remuneration-and-fees.component.scss']
})
export class RemunerationAndFeesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {EventEmitter, Injectable} from '@angular/core';
import {NotificationType} from '../../model/notification-type';
import {Notification} from '../../model/notification.model';

@Injectable({providedIn: 'root'})
export class NotifService {

  notificationEmitter = new EventEmitter<Notification>();

  success(notifMessage: string): void {
    const notification = {type: NotificationType.SUCCESS, message: notifMessage};
    this.notificationEmitter.emit(notification);
  }


  error(notifMessage: string) {
    const notification = {type: NotificationType.ERROR, message: notifMessage};
    this.notificationEmitter.emit(notification);
  }

}

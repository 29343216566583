import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataTransferService} from '../../../core/services/data-transfer.service';
import {AdminTabsService} from '../../../core/services/admin-tabs.service';
import {EmailFormComponent} from '../../../shared/email-form/email-form.component';
import {NotifService} from '../../../core/services/notification.service';
import {AdminOffersService} from '../../../core/services/admin-offers.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormatDateService} from '../../../core/services/format-date.service';
import {UserQuoteModel} from '../../../model/user-quote.model';
import {EmployerQuoteModel} from '../../../model/employer-quote.model';
@Component({
  selector: 'app-user-quotes',
  templateUrl: './quote-page.component.html',
  styleUrls: ['./quote-page.component.scss']
})
export class QuotePageComponent implements OnInit {
  userQuote: UserQuoteModel;
  employerQuote: EmployerQuoteModel[];
  quoteCode: string;
  employerQuoteId: number;
  codePositionInUrl = 7;
  codeLength = 8;
  showPurchaseQuoteDialog: boolean;
  showNtuDialog: boolean;
  showAddNotePanel = false;
  addNoteForm: FormGroup;
  previousNotes = [];
  isQuoteFinalized = false;
  @ViewChild(EmailFormComponent, {static: false}) emailFormComponent: EmailFormComponent;

  constructor(private dataTransferService: DataTransferService,
              private router: Router,
              private adminTabsService: AdminTabsService,
              private notificationService: NotifService,
              private adminOffersService: AdminOffersService,
              private formatDateService: FormatDateService) { }

  ngOnInit() {
    this.quoteCode = this.router.url.substr(this.codePositionInUrl, this.codeLength);
    this.addNoteForm = new FormGroup({
      addNoteTextarea: new FormControl('', Validators.required)
    });
    if (this.adminTabsService.tabIndex === 0 || (!this.adminTabsService.tabIndex && this.quoteCode.length === 8)) {
      this.getUsersQuote();
      this.dataTransferService.getUserNotes(this.quoteCode).subscribe(success => {
        this.previousNotes = success.map(note => ({
            ...note, date: note.date[1] + '/' + note.date[2] + '/' + note.date[0]
          })
        );
      }, error => this.notificationService.error(error.message));
    } else {
      this.getEmployersQuote();
      this.dataTransferService.getEmployerNotes(this.quoteCode).subscribe(success => {
        this.previousNotes = success.map(note => ({
          ...note, date: note.date[1] + '/' + note.date[2] + '/' + note.date[0]
          })
        );
      }, error => this.notificationService.error(error.message));
    }
  }

  getUsersQuote() {
    this.dataTransferService.getSingleQuoteData(this.quoteCode).then(singleQuote => {
      this.userQuote = singleQuote;
      this.userQuote.status === 'NTU' ? this.userQuote.status = 'N T U' : this.userQuote.status = this.userQuote.status.replace('_', ' ');
      this.userQuote.dateOfBirth = this.formatDateService.changeDateFormat(singleQuote.dateOfBirth);
      this.userQuote.insuranceRequests = singleQuote.insuranceRequests.map(insRequests => ({
        ...insRequests,
        dateOfBirth: this.formatDateService.changeDateFormat(insRequests.dateOfBirth),
        excess: insRequests.excess !== -1 ? insRequests.excess : 0,
        beneficiaryType: insRequests.beneficiaryType === 'CHILDREN' ? 'Child' : insRequests.beneficiaryType.replace('_', ' '),
        proposedPlanProvider: insRequests.proposedPlanProvider ? insRequests.proposedPlanProvider.replace(/[_]/g, ' ') : null,
        renewalDate: insRequests.renewalDate ? this.formatDateService.changeDateFormat(insRequests.renewalDate) : insRequests.renewalDate
      }));
      this.addBeneficiaryOrderNumber(singleQuote);
      this.adminOffersService.setCodeQuote(this.quoteCode);
      this.isQuoteFinalized = this.userQuote.status === 'PURCHASED' || this.userQuote.status === 'N T U';
    }, error => this.notificationService.error(error.message));
  }

  getEmployersQuote() {
    this.employerQuoteId = parseInt(this.router.url.substr(this.codePositionInUrl, this.router.url.length), 10);
    this.dataTransferService.getAllEmployerQuotes().subscribe(employerQuotes => {
      this.employerQuote = employerQuotes.filter(quote => quote.id === this.employerQuoteId).map(quote => ({
        ...quote,
        renewalDate: this.formatDateService.changeDateFormat(quote.renewalDate),
        noEmployees: quote.noEmployees.substr(5, quote.noEmployees.length).replace('_TO_', ' - ').replace('_OR_MORE', ' or more'),
        status: quote.status === 'NTU' ? 'N T U' : quote.status.replace('_', ' ')
      }));
      this.isQuoteFinalized = this.employerQuote[0].status === 'PURCHASED' || this.employerQuote[0].status === 'N T U';
    }, error => this.notificationService.error(error.message));
  }

  backToTab() {
    this.adminTabsService.setTabIndex(this.adminTabsService.tabIndex);
    this.router.navigate(['/admin']);
  }

  submitEmailForm(userData) {
    const requestData = {
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      quoteCode: this.quoteCode
    };
    this.dataTransferService.emailFormSubmit(requestData).subscribe(
      () => {
        this.userQuote.email = userData.email;
        this.userQuote.status = 'Quote';
      },
      error => {
        this.emailFormComponent.emailForm.reset();
        this.notificationService.error(error.message);
      }
    );
  }

  hideQuoteDialog() {
    this.showPurchaseQuoteDialog = false;
    this.showNtuDialog = false;
  }

  hideNoteDialog() {
    this.showAddNotePanel = false;
  }

  addNote() {
    const userNote = {
      quoteCode: this.quoteCode,
      text: this.addNoteForm.controls.addNoteTextarea.value
    };
    if (this.userQuote) {
      this.dataTransferService.addUserNote(userNote).subscribe(
        success =>
          this.previousNotes = [...this.previousNotes,
            {author: success.fullName, date: this.getFormattedDate(), text: this.addNoteForm.controls.addNoteTextarea.value}],
        error => this.notificationService.error(error.message)
      );
    }
    if (this.employerQuote) {
      const employerNote = {
        employerId: this.employerQuote[0].id,
        text: this.addNoteForm.controls.addNoteTextarea.value
      };
      this.dataTransferService.addEmployerNote(employerNote).subscribe(
        success =>
          this.previousNotes = [...this.previousNotes,
            {author: success.fullName, date: this.getFormattedDate(), text: this.addNoteForm.controls.addNoteTextarea.value}],
        error => this.notificationService.error(error.message)
      );
    }
    this.hideNoteDialog();
  }

  addBeneficiaryOrderNumber(singleQuote) {
    let index = 1;
    for (let i = 1; i < singleQuote.insuranceRequests.length; i++) {
      const insReq = singleQuote.insuranceRequests[i];
      if (insReq.beneficiaryType === singleQuote.insuranceRequests[i - 1].beneficiaryType.replace(/ [0-9]/g, '')) {
        insReq.beneficiaryType = insReq.beneficiaryType + ' ' + (index + 1);
        index++;
      } else {
        index = 1;
        insReq.beneficiaryType = insReq.beneficiaryType + ' ' + index;
      }
    }
  }

  getFormattedDate() {
    const date = new Date();
    return  (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {IntrovideoComponent, SafePipe} from './home/introvideo/introvideo.component';
import { PlaybuttonComponent } from './home/introvideo/playbutton/playbutton.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRadioModule, MatSliderModule, MatTableModule} from '@angular/material';
import { MatAutocompleteModule, MatCardModule, MatTabsModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { EmailFormComponent } from './shared/email-form/email-form.component';
import { MatDialogModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdminModule } from './admin/admin.module';
import { DatePipe } from '@angular/common';
import { AuthGuard } from './core/guards/admin.route.guard';
import { InsurancePageComponent } from './insurance/insurance-page.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './insurance/progress-bar/progress-bar.component';
import { EmployersInsuranceComponent } from './employers-insurance/employers-insurance.component';
import { CurrentInsuranceDetailsComponent } from './insurance/current-insurance-details/current-insurance-details.component';
import { NgxY2PlayerModule } from 'ngx-y2-player';
import { SharedModule } from './shared/shared.module';
import { MainBeneficiaryInfoComponent } from './insurance/main-beneficiary-info/main-beneficiary-info.component';
import { PageAdvanceComponent } from './insurance/page-advance/page-advance.component';
import { PlanPackageComponent } from './insurance/plan-package/plan-package.component';
import { BenefitsComponent } from './insurance/benefits/benefits.component';
import { LastStepComponent } from './insurance/last-step/last-step.component';
import { SimilarOrFullComponent } from './insurance/similar-or-full/similar-or-full.component';
import { QuestionmarkComponent } from './insurance/questionmark/questionmark.component';
import { FindMoreCardComponent } from './home/find-more/find-more-card/find-more-card.component';
import { FindMoreComponent } from './home/find-more/find-more.component';
import { TipsComponent } from './home/tips/tips.component';
import { NewToHealthComponent } from './benefits-pages/new-to-health/new-to-health.component';
import { EmployerInformationComponent } from './benefits-pages/employer-information/employer-information.component';
import { LifetimeCommunityRatingComponent } from './benefits-pages/lifetime-community-rating/lifetime-community-rating.component';
import { MaternityBenefitsComponent } from './benefits-pages/maternity-benefits/maternity-benefits.component';
import { BlogComponent } from './blog/blog.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { UsersOptionsPageComponent } from './email-options-pages/users-options-page/users-options-page.component';
import { ProposedPlanOptionsComponent } from './email-options-pages/proposed-plan-options/proposed-plan-options.component';
import { UserPurchasedPlanComponent } from './email-options-pages/user-purchased-plan/user-purchased-plan.component';
import { EmployerPurchasedPlanComponent } from './email-options-pages/employer-purchased-plan/employer-purchased-plan.component';
import {PlanOptionsComponent} from './email-options-pages/plan-options/plan-options.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CookiesPolicyComponent } from './home/cookies-policy/cookies-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IntrovideoComponent,
    PlaybuttonComponent,
    PagenotfoundComponent,
    InsurancePageComponent,
    ProgressBarComponent,
    EmployersInsuranceComponent,
    CurrentInsuranceDetailsComponent,
    MainBeneficiaryInfoComponent,
    PageAdvanceComponent,
    PlanPackageComponent,
    BenefitsComponent,
    LastStepComponent,
    SimilarOrFullComponent,
    QuestionmarkComponent,
    FindMoreCardComponent,
    FindMoreComponent,
    TipsComponent,
    NewToHealthComponent,
    EmployerInformationComponent,
    LifetimeCommunityRatingComponent,
    MaternityBenefitsComponent,
    BlogComponent,
    FaqPageComponent,
    UsersOptionsPageComponent,
    ProposedPlanOptionsComponent,
    UserPurchasedPlanComponent,
    EmployerPurchasedPlanComponent,
    PlanOptionsComponent,
    AboutUsComponent,
    SafePipe,
    CookiesPolicyComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSliderModule,
    MatDialogModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatAutocompleteModule,
    AdminModule,
    FormsModule,
    MatProgressBarModule,
    NgxY2PlayerModule,
    SharedModule
  ],
  providers: [MatDatepickerModule, DatePipe, AuthGuard, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [EmailFormComponent]
})
export class AppModule { }

import {Component, OnInit} from '@angular/core';
import {DataTransferService} from '../../core/services/data-transfer.service';
import {AdminTabsService} from '../../core/services/admin-tabs.service';
import {AdminOffersService} from '../../core/services/admin-offers.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  selectedTabIndex: any;

  constructor(private dataTransferService: DataTransferService,
              private adminTabsService: AdminTabsService,
              private adminOffersService: AdminOffersService) { }

  ngOnInit() {
    this.adminOffersService.setInsuranceRequest(null);
    this.selectedTabIndex = this.adminTabsService.tabIndex ? this.adminTabsService.getTabIndex() : this.adminTabsService.setTabIndex(0);
  }
  setTabIndexInService(event) {
    this.adminTabsService.setTabIndex(event.index);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlanPackageResponse} from '../../model/plan-package.model';
import { AgeIntervalResponse } from 'src/app/model/age-interval-response.model';
import {EmailFormModel} from '../../model/email-form.model';
import {UserQuoteModel} from '../../model/user-quote.model';
import {EmployerQuoteModel} from '../../model/employer-quote.model';
import {Note} from '../../model/note.model';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  constructor(private httpClient: HttpClient) { }

  getPlanPackages(): Observable<PlanPackageResponse[]> {
    return this.httpClient.get<PlanPackageResponse[]>('/package/all');
  }

  getProviders(): Observable<string[]> {
    return this.httpClient.get<string[]>('/plan/providers');
  }

  getProviderPlans(provider): Observable<string[]> {
    const request = encodeURIComponent(provider);
    return this.httpClient.get<string[]>(`/plan/provider?provider=${request}`, {});
  }

  getAgeIntervals() {
    return this.httpClient.get<AgeIntervalResponse>('/beneficiary/age-interval');
  }

  getEmployeesNumbers(): Observable<string[]> {
    return this.httpClient.get<string[]>('/employer/size');
  }

  sendCollectedData(data): Observable<any> {
    return this.httpClient.post<any>('/insurance/', data);
  }

  employerFormSubmit(data): Observable<any> {
    return this.httpClient.post<any>('/employer/', data);
  }

  postProposePlan(data, reqString): Observable<any> {
    return this.httpClient.post<any>(reqString, data);
  }

  emailFormSubmit(data): Observable<EmailFormModel> {
    return this.httpClient.put<EmailFormModel>(
      `/insurance/user?quoteCode=${data.quoteCode}&email=${encodeURIComponent(data.email)}&phoneNo=${data.phoneNumber}`, {});
  }

  getUserQuotesData(pageSize, pageNumber): Observable<UserQuoteModel[]> {
    return this.httpClient.get<UserQuoteModel[]>(`/insurance/page?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  getUserQuotesCount(): Observable<number> {
    return this.httpClient.get<number>('/insurance/count');
  }

  getEmployerQuotesCount(): Observable<number> {
    return this.httpClient.get<number>('/employer/count');
  }

  getAllEmployerQuotes(): Observable<EmployerQuoteModel[]> {
    return this.httpClient.get<EmployerQuoteModel[]>('/employer/all');
  }

  getEmployerQuotesData(pageSize, pageNumber): Observable<EmployerQuoteModel[]> {
    return this.httpClient.get<EmployerQuoteModel[]>(`/employer/page?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  getSingleQuoteData(quoteCode) {
    return this.httpClient.get<UserQuoteModel>(`/insurance/by-quote?quoteCode=${quoteCode}`).toPromise();
  }

  getOffersData(reqId): Observable<any> {
    return this.httpClient.get<any>(`/insurance/request/${reqId}/details`);
  }

  offersOptionSubmit(insuranceRequestId, planName): Observable<string> {
    return this.httpClient.post<string>(`/insurance/request/${insuranceRequestId}/details/select-option`, planName);
  }

  finalizeUserQuote(finalizeData): Observable<any> {
    return this.httpClient.post<any>('/insurance/request/finalize', finalizeData);
  }

  finalizeEmployerQuote(employerId, operation): Observable<any> {
    return this.httpClient.post<any>(`/employer/${employerId}/finalize?operation=${operation}`, {});
  }

  addUserNote(noteBody): Observable<Note> {
    return this.httpClient.put<Note>('/insurance/note', noteBody);
  }

  addEmployerNote(noteBody): Observable<Note> {
    return this.httpClient.put<Note>('/employer/note', noteBody);
  }

  getUserNotes(quoteCode): Observable<any> {
    return this.httpClient.get<any>(`/insurance/notes?quote=${quoteCode}`);
  }

  getEmployerNotes(quoteCode): Observable<any> {
    return this.httpClient.get<any>(`/employer/notes?employerId=${quoteCode}`);
  }

  updateBudgetsAndExcesses(data): Observable<any> {
    return this.httpClient.put<any>('/package', data);
  }

  activateAccount(accountId, token): Observable<any> {
    return this.httpClient.post<any>(`/activate?accountId=${accountId}&token=${token}`, {});
  }

  getPlanDetails(hash, url): Observable<any> {
    return this.httpClient.get<any>(url + hash);
  }
}

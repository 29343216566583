import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifetime-community-rating',
  templateUrl: './lifetime-community-rating.component.html',
  styleUrls: ['./lifetime-community-rating.component.scss']
})
export class LifetimeCommunityRatingComponent implements OnInit {
  questionsAndAnswers = [
    {
      question: 'Why was Lifetime Community Rating introduced ?',
      answer: 'As younger people claim less, it is important that there is a continuing influx of younger people to keep premiums manageable for' +
        ' everybody. Lifetime Community Rating encourages younger people to take out private health insurance at a younger age which helps control' +
        ' premium inflation.'
    },
    {
      question: 'Can I cancel my health insurance for a period of time without a loading applying ?',
      answer: 'Breaks in cover of up to 13 weeks are allowed without it affecting your loading.'
    },
    {
      question: 'How long will I have to pay a loading for? ',
      answer: 'Loadings apply for a maximum of 10 years.'
    },
    {
      question: 'Will I receive credit for previous periods of in-patient cover?',
      answer: 'Yes, previous cover is taken into account when calculating your loading if you take out private health insurance after 30 April' +
        ' 2015. If you had continuous cover for the period 1 May 2009 to 30 April 2015, the insurer would consider you to have had continuous cover' +
        ' since the age of 23. If you have cover outside of these dates, you must be able to prove this.'
    },
    {
      question: 'I am moving to Ireland. Will I have to pay a Lifetime Community Rating loading? ',
      answer: 'You will not have to pay a Lifetime Community Rating loading if you purchase private health insurance within nine months of becoming' +
        ' a resident in Ireland. If you do not purchase within nine months and you are aged 35 or over, you will have to pay a 2% loading of the' +
        ' gross premium for every year over the age of 34. '
    },
    {
      question: 'Is there a maximum loading that can be applied ?',
      answer: 'Yes, the maximum loading that any insurer can apply is 70% of the gross premium and this will apply for a maximum of 10 years. '
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}

export class BenefitCategories {
    categoryName: string;
    benefitsName: string[];
    isUserSelected: boolean;

    constructor(categoryName: string, benefitsName: string[], isUserSelected: boolean) {
      this.categoryName = categoryName;
      this.benefitsName = benefitsName;
      this.isUserSelected = isUserSelected;
    }
  }

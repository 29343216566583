import {Component, OnInit, Input} from '@angular/core';
import {InsurancePageComponent} from '../insurance-page.component';
import {User} from '../../model/user.model';
import {Navigation} from '../../model/navigation.model';

@Component({
  selector: 'app-ins-page-review',
  templateUrl: './similar-or-full.component.html',
  styleUrls: ['./similar-or-full.component.scss']
})
export class SimilarOrFullComponent implements OnInit {
  @Input() parent: InsurancePageComponent;
  navigation: Navigation;
  mainUser: User;
  selectedPlanValue: string;

  constructor() {
  }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
    this.navigation = this.parent.navigation;
  }

  setPlan(value) {
    this.selectedPlanValue = value;
    this.mainUser.similarPlan = this.selectedPlanValue === 'Similar';
  }

  refresh() {
    this.selectedPlanValue = null;
  }
}

import {User} from './user.model';

export class Navigation {
  user: User;
  maxTabsNumber: number;
  selectedIndex: number;

  constructor(user, selectedIndex, maxTabsNumber) {
    this.user = user;
    this.maxTabsNumber = maxTabsNumber;
    this.selectedIndex = selectedIndex;
  }
}

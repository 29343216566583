import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AdminTabsService {
  tabIndex: number;

  setTabIndex(tabIndex) {
    this.tabIndex = tabIndex;
  }

  getTabIndex() {
    return this.tabIndex;
  }
}

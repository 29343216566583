import { Component, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-questionmark',
  templateUrl: './questionmark.component.html',
  styleUrls: ['./questionmark.component.scss']
})
export class QuestionmarkComponent {
  @Input() explanation: string;
  @Input() url: string;
  @Input() urlText: string;
  @Input() inside = false;
  @ViewChild('questionMark', {static: false}) questionMark: ElementRef;
  constructor() {}
  putInside() {
    if (this.inside) {
      const repeater = setInterval(() => {
        const tooltip = this.questionMark.nativeElement.parentElement.querySelector('ngb-tooltip-window');
        if (tooltip) {
          this.questionMark.nativeElement.append(tooltip);
          clearInterval(repeater);
        }
      }, 100);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminModule } from './admin/admin.module';
import {LoginPageComponent} from './admin/auth/login-page/login-page.component';
import {ForgotPasswordComponent} from './admin/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './admin/auth/change-password/change-password.component';
import {AdminPageComponent} from './admin/admin-page/admin-page.component';
import { AuthGuard } from './core/guards/admin.route.guard';
import {InsurancePageComponent} from './insurance/insurance-page.component';
import { EmployersInsuranceComponent } from './employers-insurance/employers-insurance.component';
import { QuotePageComponent } from './admin/admin-page/quote-page/quote-page.component';
import {SettingsPageComponent} from './admin/admin-page/settings-page/settings-page.component';
import {ManagerComponent} from './admin/admin-page/manager/manager.component';
import {CostManagerComponent} from './admin/admin-page/cost-manager/cost-manager.component';
import {OffersPageComponent} from './admin/admin-page/quote-page/offers-page/offers-page.component';
import {NewToHealthComponent} from './benefits-pages/new-to-health/new-to-health.component';
import {EmployerInformationComponent} from './benefits-pages/employer-information/employer-information.component';
import {LifetimeCommunityRatingComponent} from './benefits-pages/lifetime-community-rating/lifetime-community-rating.component';
import {MaternityBenefitsComponent} from './benefits-pages/maternity-benefits/maternity-benefits.component';
import { BlogComponent } from './blog/blog.component';
import {FaqPageComponent} from './faq-page/faq-page.component';
import {RemunerationAndFeesComponent} from './shared/footer/remuneration-and-fees/remuneration-and-fees.component';
import {PrivacyNoticeComponent} from './shared/footer/privacy-notice/privacy-notice.component';
import {PrivacyStatementComponent} from './shared/footer/privacy-statement/privacy-statement.component';
import {TermsOfBusinessComponent} from './shared/footer/terms-of-business/terms-of-business.component';
import {UsersOptionsPageComponent} from './email-options-pages/users-options-page/users-options-page.component';
import {ActivateAccountComponent} from './admin/auth/activate-account/activate-account.component';
import {ProposedPlanOptionsComponent} from './email-options-pages/proposed-plan-options/proposed-plan-options.component';
import {UserPurchasedPlanComponent} from './email-options-pages/user-purchased-plan/user-purchased-plan.component';
import {EmployerPurchasedPlanComponent} from './email-options-pages/employer-purchased-plan/employer-purchased-plan.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {CookiesPolicyComponent} from './home/cookies-policy/cookies-policy.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'insurance',
    component: InsurancePageComponent
  },
  {
    path: 'employers-insurance',
    component: EmployersInsuranceComponent
  },
  {
    path: 'benefits/new-to-health',
    component: NewToHealthComponent
  },
  {
    path: 'benefits/employer-information',
    component: EmployerInformationComponent
  },
  {
    path: 'benefits/lifetime-community-rating',
    component: LifetimeCommunityRatingComponent
  },
  {
    path: 'benefits/maternity-benefits',
    component: MaternityBenefitsComponent
  },
  // {
  //   path: 'blog',
  //   children: [
  //     {path: '', component: BlogComponent},
  //     {path: ':slug', component: BlogComponent},
  //   ]
  // },
  {
    path: 'faq',
    component: FaqPageComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'activate-account/:token/:id',
    component: ActivateAccountComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {path: '', component: AdminPageComponent},
      {path: 'settings', component: SettingsPageComponent},
      {path: 'manage-admins', component: ManagerComponent},
      {path: 'manage-costs', component: CostManagerComponent},
      {path: ':quote-path', children: [
          {path: '', component: QuotePageComponent},
          {path: 'offers', component: OffersPageComponent}
      ]}
    ]
  },
  {
    path: 'recover-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'remuneration-and-fees',
    component: RemunerationAndFeesComponent
  },
  {
    path: 'privacy-notice',
    component: PrivacyNoticeComponent
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent
  },
  {
    path: 'terms-of-business',
    component: TermsOfBusinessComponent
  },
  {
    path: 'proposed/:hash',
    component: ProposedPlanOptionsComponent
  },
  {
    path: 'purchased/:hash',
    component: UserPurchasedPlanComponent
  },
  {
    path: 'employer/purchased/:hash',
    component: EmployerPurchasedPlanComponent
  },
  {
    path: 'options/:codeQuote',
    component: UsersOptionsPageComponent
  },
  { path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}), AdminModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../core/services/user.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {RegisterUserRequest} from '../../../model/register-request.model';
import {Router} from '@angular/router';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  registerForm: FormGroup;
  formSubmitted = false;
  passPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,50}$';
  registerSuccess = '';
  registerError = '';
  hidePassword = [true, true];

  constructor(private userService: UserService,
              private router: Router,
              private notificationService: NotifService) { }

  ngOnInit() {
    this.registerForm = new FormGroup({
      fullName: new FormControl ('', Validators.required),
      email : new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern(this.passPattern)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(this.passPattern)])
    }, {
      validators: this.validatePassword.bind(this)
    });
  }

  get regFormControls() { return this.registerForm.controls; }


  validatePassword(passwordsFormGroup: AbstractControl) {
    const password = passwordsFormGroup.get('password').value;
    const confirmPassword = passwordsFormGroup.get('confirmPassword').value;
    return (password !== confirmPassword) ? {mismatchedPasswords: true} : null;
  }

  registerSubmit() {
    this.formSubmitted = true;
    if (this.registerForm.valid) {
      const fullNameValue = this.registerForm.get('fullName').value;
      const emailValue = this.registerForm.get('email').value;
      const passwordValue = this.registerForm.get('password').value;
      const confirmPasswordValue = this.registerForm.get('confirmPassword').value;
      const registerRequest = new RegisterUserRequest(confirmPasswordValue, emailValue, fullNameValue, passwordValue);
      this.userService.registerUser(registerRequest).subscribe(
        () => {
          this.notificationService.success('Registration successful.');
          setTimeout( () => { this.router.navigate(['/login']); }, 2000);
        },
        error => this.notificationService.error(error.error)
      );
    }
  }

  togglePasswordVisibility(index) {
    this.hidePassword[index] = !this.hidePassword[index];
  }

}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ManageAdminsService} from '../../../core/services/manage-admins.service';
import {Router} from '@angular/router';
import {AdminAccount} from '../../../model/admin-account.model';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {

  inviteAdminForm: FormGroup;
  inviteSuccessMessage = null;
  inviteErrorMessage = null;
  adminsErrorMessage = null;
  admins: AdminAccount[] = [];

  constructor(
    private manageAdminsService: ManageAdminsService,
    private router: Router,
    private notifService: NotifService
  ) {}

  ngOnInit() {
    this.manageAdminsService.getAccounts().subscribe(
      success => this.admins = success,
      error => {
        this.adminsErrorMessage = error.error;
        setTimeout(() => { this.adminsErrorMessage = null; }, 3000);
      }
    );
    this.inviteAdminForm = new FormGroup({
      newName: new FormControl('', [Validators.required]),
      newEmail: new FormControl('', [Validators.required, Validators.email])
    });
  }

  invite() {
    if (this.inviteAdminForm.valid) {
      const reqData = {
        fullName: this.inviteAdminForm.get('newName').value,
        email: this.inviteAdminForm.get('newEmail').value
      };
      this.manageAdminsService.sendInvite(reqData).subscribe(
        () => {
          this.inviteSuccessMessage = ' Invite sent';
          setTimeout(() => { this.inviteSuccessMessage = null; }, 3000);
          this.inviteAdminForm.reset();
        },
        error => {
          this.inviteErrorMessage = error.error;
          setTimeout(() => { this.inviteErrorMessage = null; }, 3000);
        }
      );
    }
  }
  banAccount(admin) {
    this.manageAdminsService.banAccount(admin.id).subscribe(
      () => {
        this.notifService.success('Account banned successfully.');
        admin.status = 'BANNED';
        this.inviteAdminForm.reset();
      },
      error => {
        this.notifService.error(error);
      }
    );
  }
  enableAccount(admin) {
    this.manageAdminsService.enableAccount(admin.id).subscribe(
      () => {
        this.notifService.success('Account activated successfully.');
        admin.status = 'ACTIVE';
        this.inviteAdminForm.reset();
      },
      error => {
        this.notifService.error(error);
      }
    );
  }
  get inviteControls() { return this.inviteAdminForm.controls; }
}

import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AdminOffersService} from '../../../../core/services/admin-offers.service';
import {DataTransferService} from '../../../../core/services/data-transfer.service';
import {Router} from '@angular/router';
import {NotifService} from '../../../../core/services/notification.service';

@Component({
  selector: 'app-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss']
})
export class OffersPageComponent implements OnInit {
  tableColumnsNames = ['Benefits', 'Irish', 'Laya', 'VHI'];
  vhiSelected = false;
  layaSelected = false;
  irishLifeSelected = false;
  offersData = [];
  rowLabels = ['Plan Name', 'Plan Type', 'Adult', 'Public Hospitals', 'Private Hospitals', 'Excess', 'Day Cases - Private Hospitals',
    'Day Cases - High Tech', 'Semi Private - High Tech', 'Private - High Tech'];
  dataColumns = {Irish: [], Laya: [], VHI: []};
  dataSource = [];
  selectedByUser = [];
  selectedIndexes = [];

  constructor(private adminOffersService: AdminOffersService,
              private dataTransferService: DataTransferService,
              private location: Location,
              private router: Router,
              private notificationService: NotifService) {
  }

  ngOnInit() {
    if (!this.adminOffersService.insuranceRequest) {
      this.router.navigate(['/admin']);
    } else {
      this.dataSource = [];
      this.dataTransferService.getOffersData(this.adminOffersService.insuranceRequest.id).subscribe(
        success => {
          this.offersData = this.getDataArray(success);
          this.getRowIndexesToHighlight(success);
        },
        error => this.notificationService.error(error.message)
      );
    }
  }

  selectOption(option) {
    switch (option) {
      case 'irishLife':
        this.irishLifeSelected = true;
        this.vhiSelected = false;
        this.layaSelected = false;
        this.submitSelectedOption(this.dataColumns.Irish[0]);
        break;
      case 'laya':
        this.layaSelected = true;
        this.irishLifeSelected = false;
        this.vhiSelected = false;
        this.submitSelectedOption(this.dataColumns.Laya[0]);
        break;
      case 'vhi':
        this.vhiSelected = true;
        this.layaSelected = false;
        this.irishLifeSelected = false;
        this.submitSelectedOption(this.dataColumns.VHI[0]);
        break;
    }
  }

  generateProvidersArrays(dataSource) {
    dataSource.forEach(plan => {
      switch (plan.recommendedPlanDetails.provider.toLowerCase()) {
        case 'irish_life_health':
          this.dataColumns.Irish = this.getProviderBenefits(plan, this.dataColumns.Irish);
          if (this.adminOffersService.insuranceRequest.selectedOptionPlanName === this.dataColumns.Irish[0]) {
           this.irishLifeSelected = true;
          }
          break;
        case 'laya':
          this.dataColumns.Laya = this.getProviderBenefits(plan, this.dataColumns.Laya);
          if (this.adminOffersService.insuranceRequest.selectedOptionPlanName === this.dataColumns.Laya[0]) {
            this.layaSelected = true;
          }
          break;
        case 'vhi':
          this.dataColumns.VHI = this.getProviderBenefits(plan, this.dataColumns.VHI);
          if (this.adminOffersService.insuranceRequest.selectedOptionPlanName === this.dataColumns.VHI[0]) {
            this.vhiSelected = true;
          }
          break;
      }
    });
  }

  getProviderBenefits(plan, providerArray) {
    let benefitsValues = [];
    benefitsValues = [...providerArray,
      plan.recommendedPlanDetails.name, plan.recommendedPlanDetails.type, '€' + plan.recommendedPlanDetails.price, plan.hospitals[0].value,
      plan.hospitals[1].value, '€' + plan.recommendedPlanDetails.excess, plan.hospitals[1].dayCases, plan.hospitals[2].dayCases,
      plan.hospitals[2].value, plan.hospitals[3].value];
    plan.benefitCategories.forEach(category => benefitsValues = [...benefitsValues, category.benefitDTO.value]);
    return benefitsValues;
  }

  getDataArray(dataSource) {
    this.generateProvidersArrays(dataSource);
    dataSource[0].benefitCategories.forEach(category => this.rowLabels = [...this.rowLabels, category.categoryDTO.name]);
    for (const rowLabel of this.rowLabels) {
      const i = this.rowLabels.indexOf(rowLabel);
      this.dataSource = [...this.dataSource,
        {Benefits: rowLabel, Irish: this.dataColumns.Irish[i], Laya: this.dataColumns.Laya[i], VHI: this.dataColumns.VHI[i]}];
    }
    return this.dataSource;
  }

  submitSelectedOption(provider) {
    this.dataTransferService.offersOptionSubmit(this.adminOffersService.insuranceRequest.id, provider).subscribe(
      () => {},
      error => this.notificationService.error(error.message)
    );
  }

  getRowIndexesToHighlight(data) {
    data[0].benefitCategories.forEach(category => {
      if (category.userSelectedCategory === true) {
        this.selectedByUser = [...this.selectedByUser, category.categoryDTO.name.toLowerCase()];
      }
    });
    this.rowLabels.forEach(row => {
      const i = this.rowLabels.indexOf(row);
      if (this.selectedByUser.includes(row.toLowerCase())) {
        this.selectedIndexes = [...this.selectedIndexes, i];
      }
    });
  }

}

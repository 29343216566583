import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  qAndAForNewInsurance = [
    {
      question: 'What is a waiting period?',
      answer: `Waiting periods apply to anyone purchasing health insurance for the first time. You will be covered for accidents or injuries ` +
        `immediately, but not illnesses. If you think about it, it makes sense to apply waiting periods for existing illnesses, otherwise we would` +
        `only take out health insurance when we get sick! <br><br> So now the most important part is to understand what the waiting periods are….` +
        `<br><br>` +
        `Accident or Injury – No Waiting Period <br> New Conditions – 26 Weeks <br>Pre-Existing Conditions - 5 Years<br> Maternity/` +
        `Infertility Benefits - 52 Weeks <br> Upgrade Waiting Period - 2 Years <br> New Conditions are illnesses that develop after you take out` +
        ` the policy. <br><br> A pre-existing condition will be an ailment, illness or condition where the signs or symptoms existed at any time in` +
        ` the period of 6 months prior to the insurance commencing. <br> An ‘upgrade waiting period’ of 2 years is applied if you are switching ` +
        `to a plan with a higher benefit and you have an existing condition at the time of the upgrade. <br> Infants born to policyholders will ` +
        `not serve a waiting period if they are added to a policy within 13 weeks of their date of birth.`
    },
    {
      question: 'What is an excess?',
      answer: 'An excess is the first part of any insurance claim that you have to pay yourself. An inpatient excess is the amount you pay on' +
        ' private hospital claims. The higher the excess, the lower your health insurance premium will be. The excess is per stay not per night,' +
        ' unless stated otherwise. So, if you see €150 per claim on your policy and you stay in private hospital for a week, you pay the first' +
        ' €150 only and your insurer will pay the rest. '
    },
    {
      question: 'What is a co-payment?',
      answer: 'A co-payment is a large excess '
    },
    {
      question: 'What are Day-to-day Expenses?',
      answer: 'Day-to-day expenses are everyday health costs such as visits to Consultants, GP’s, Dentists, Physiotherapists, Physical Therapists,' +
        ' Dieticians, and other alternative practitioners. The amount of cover depends on the plan you have selected, and this information will be' +
        ' included within your table of cover. '
    },
    {
      question: 'What is a consultant? ',
      answer: 'A consultant is a medical practitioner who has experience in a designated speciality in a hospital and is registered by the Irish' +
        ' Medical Council. '
    },
    {
      question: 'How does my outpatient excess work?',
      answer: 'An outpatient excess applies to everyday medical expenses. It is the amount that must be reached before you can claim money back' +
        ' from your insurer. This can be confusing, and a lot of members think they may be entitled to money back when unfortunately, they are not.' +
        ' For example, if you were on a plan with a €100 outpatient excess and the amount shown on your table of cover for GP visits is 50%, if ' +
        'you had 2 GP visits at €60 each, members would think that since you spent €120, they would be able to claim €20 back. '
    },
    {
      question: 'What is a shortfall?',
      answer: 'A shortfall is the amount you must pay yourself and is generally on a nightly basis. '
    },
    {
      question: 'Is Public Healthcare free? ',
      answer: 'If you are admitted to a public hospital, a charge of €80 per day applies up to a maximum charge of €800 in any 12 consecutive' +
        ' months. No charge applies to medical card holders. '
    },
    {
      question: 'Will my age affect my health insurance premium? ',
      answer: `No. The health insurance system applying in Ireland is called lifetime community rating. In a lifetime community rated system ` +
        `everyone pays the same premium for a given health insurance plan, except as follows: <br><br> From 1 May 2015, if you first buy health ` +
        `insurance at age 35 or over or allow your insurance to lapse for more than 13 weeks at age 35 or over, an age at entry loading may apply ` +
        `to your premium.<br> The premium may be reduced by up to 10% for members of group schemes.<br> The premium for children must be no more ` +
        `than 50% of the adult premium.<br> The premium for those aged 18-25 may be reduced.<br>Pensioners who are members of restricted ` +
        `membership insurers may have their premiums reduced.`
    },
    {
      question: 'Can I buy a corporate plan even though I am not an employee? ',
      answer: 'Yes. Corporate plans are available to all regardless of whether you are an employee or not. '
    },
    {
      question: 'I am moving to Ireland. Can I get private health insurance? ',
      answer: `Yes, you can take out health insurance if you become a resident of Ireland. You may, however, have to serve a waiting period. If ` +
        `you are 35 or older when you move to Ireland you must purchase private health insurance within 9 months, otherwise, a Lifetime Community` +
        ` Rating Loading will be applied to your premium. You can read about this <a href="/benefits/lifetime-community-rating"> *here.<a/> `
    },
    {
      question: 'I currently hold health insurance abroad and I am returning to Ireland to live. Will my foreign health insurance be taken into' +
        ' account for waiting periods on Irish health insurance?',
      answer: 'Health Insurers in Ireland are not obliged to take foreign health insurance into account. However, they may waive the waiting period' +
        ' at their discretion as the law imposes a maximum on waiting periods, but no minimum. '
    },
    {
      question: 'Can I claim tax relief on my health insurance? ',
      answer: 'Private health insurance premiums are subject to income tax relief at source. The tax relief (of 20% premium) is restricted to' +
        ' €1,000 gross premium in respect of adults and to €500 gross premium for children and full-time dependent students aged 18 – 21.'
    },
    {
      question: 'What is the community rating health insurance levy?',
      answer: 'The Irish state supports the community rated system by providing age related health credits in respect of older people and less' +
        ' healthy people, to help meet the expected higher cost of health insurance for this group. As a result, all people pay the same premiums' +
        ' net of these tax credits for their health insurance.'
    },
    {
      question: 'Can I get a group discount on the price quoted me for my plan? ',
      answer: `An insurer can offer up to a 10% group discount on any plan to a group of persons at the insurer's discretion.`
    },
    {
      question: 'What is a Young Adult Rate?',
      answer: 'Young Adult Rates were introduced in May 2015. Student Rates changed to age-related rates. This was introduced so members premiums' +
        ' increased gradually from a child rate to a full adult rate. Insurers can decide whether or not to provide Young Adult rates on their' +
        ' plans. '
    }
  ];

  qAndAForExistingInsurance = [
    {
      question: 'Can I change/cancel my plan mid-year?',
      answer: 'All health insurance contracts are a 12-month contract. The rule of thumb is that you cannot cancel your policy mid-contract' +
        ' however, some companies may allow you to cancel and will charge you a cancellation fee based on the government levy. All insurers must' +
        ' provide a 14-day cooling off period from the commencement of the contract, during which time you may cancel the policy without penalty.' +
        ' No claims will be paid in respect of these 14 days if you do cancel the policy. '
    },
    {
      question: 'What does continuity of cover mean?',
      answer: 'Continuity of cover is when your health insurance continues without any break when you switch from one health insurance provider to' +
        ' another. You must have served all of your waiting periods with the previous provider, otherwise your new provider will take into account' +
        ' the waiting period you have already served with your previous provider. '
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}

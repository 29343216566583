import {ChangeDetectorRef, Component} from '@angular/core';
import {NotifService} from '../../core/services/notification.service';
import {Notification} from '../../model/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  notification: Notification;

  constructor(private notifService: NotifService, private changeDetectorRef: ChangeDetectorRef) {
    this.notifService.notificationEmitter.subscribe(notification => {
      this.notification = null;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.notification = notification;
        this.changeDetectorRef.detectChanges();
      }, 50);
    });
  }
}

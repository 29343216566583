import {Component, OnInit, Input} from '@angular/core';
import {User} from '../../model/user.model';
import {Navigation} from '../../model/navigation.model';
import {InsurancePageComponent} from '../insurance-page.component';

@Component({
  selector: 'app-ins-page-advance',
  templateUrl: './page-advance.component.html',
  styleUrls: ['./page-advance.component.scss']
})
export class PageAdvanceComponent implements OnInit {
  @Input() condition: boolean;
  @Input() parent: InsurancePageComponent;
  @Input() enabled = false;
  navigation: Navigation;
  mainUser: User;

  constructor() { }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
    this.navigation = this.parent.navigation;
  }

}

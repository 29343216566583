import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss']
})
export class EmailFormComponent implements OnInit {
  emailForm: FormGroup;
  @Output() emailFormSubmit = new EventEmitter();
  @Input() email: string;
  @Input() phoneNumber: string;
  constructor() { }

  ngOnInit() {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[0-9].{2,}')]),
    });
  }

  emitEmailFormData() {
    this.emailFormSubmit.emit({email: this.emailForm.get('email').value, phoneNumber: this.emailForm.get('phoneNumber').value});
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {DataTransferService} from 'src/app/core/services/data-transfer.service';
import {NotifService} from '../../../../core/services/notification.service';
import {UserQuoteModel} from '../../../../model/user-quote.model';

@Component({
  selector: 'app-propose-plan',
  templateUrl: './propose-plan.component.html',
  styleUrls: ['./propose-plan.component.scss']
})
export class ProposePlanComponent implements OnInit {
  providerOptions = [];
  insurancePlans = [];
  planOptions = {};
  proposePlanForm: FormGroup;
  networkError: string = null;
  disabled = false;
  @Input() isEmployer: boolean;
  @Input() showProposePlanDialog;
  @Input() insuranceRequest: any;
  @Input() insuranceRequestId: number;
  @Input() isPlanProposed: boolean;
  @Input() userQuote: UserQuoteModel;
  @Output() hideProposePlanDialog = new EventEmitter();
  @Output() proposedPlanName = new EventEmitter();

  constructor(private dataTransferService: DataTransferService,
              private notifService: NotifService) { }

  ngOnInit() {
    if (!this.isEmployer && (this.userQuote.status === 'PURCHASED' || this.userQuote.status === 'N T U') ||
    this.isEmployer && this.insuranceRequest.status === 'PURCHASED' || this.insuranceRequest.status === 'N T U') {
      this.disabled = true;
    }
    this.proposePlanForm = new FormGroup({
      providerType: new FormControl({
        value: this.disabled ? this.insuranceRequest.proposedPlanProvider : '',
        disabled: this.disabled}, Validators.required),
      planName: new FormControl({
        value: this.disabled ? this.insuranceRequest.proposedPlanName : '',
        disabled: this.disabled}, Validators.required),
      sendEmail: new FormControl(false),
      additionalInfo: new FormControl({
        value: this.disabled ? this.insuranceRequest.proposedPlanAdditionalInfo : '',
        disabled: this.disabled}),
      discount: new FormControl()
    });
    this.getProviders();
  }

  getProviders() {
    this.dataTransferService.getProviders().subscribe(success => {
      this.providerOptions = success.map(provider => provider.replace(/[_]/g, ' '));
      this.networkError = null;
      success.forEach(provider =>
        this.dataTransferService.getProviderPlans(provider).subscribe(providerPlan => {
          this.planOptions[provider.replace(/[_]/g, ' ')] = providerPlan;
          this.networkError = null;
        }, error => {
          this.notifService.error(error.message);
        })
      );
    }, error => {
      this.notifService.error(error.message);
    });
  }

  setSelectedProviderPlans() {
    this.insurancePlans =  this.planOptions[this.proposePlanForm.value.providerType];
  }

  get proposePlanFormC() { return this.proposePlanForm.controls; }

  formSubmit(event) {
    let reqString = '';
    if (this.isEmployer === true) {
      reqString = '/employer/propose-plan';
      delete this.proposePlanForm.value.sendEmail;
    } else {
      reqString = '/insurance/request/propose-plan';
      delete this.proposePlanForm.value.discount;
    }
    this.proposePlanForm.value.providerType = this.proposePlanForm.value.providerType.replace(/[ ]/g, '_');
    this.dataTransferService.postProposePlan({...this.proposePlanForm.value, id: this.insuranceRequestId}, reqString).subscribe(
      () => {
        this.proposedPlanName.emit(this.proposePlanForm.value);
        this.hideDialog(event);
      },
      error => {
        this.notifService.error(error.message);
      }
    );
  }

  hideDialog(event) {
    this.hideProposePlanDialog.emit(event);
  }

}

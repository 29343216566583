import {PlanPackage} from './plan-package.model';

export class InsurancePlanOptions {
  planPackages: PlanPackage[];
  providers: string[];
  insurancePlans: string[];
  optionNames = {
    maternity: 'Maternity',
    dayToDay: 'Day to Day'
  };

  constructor() {
    this.planPackages = [];
    this.providers = [];
    this.insurancePlans = [];
  }
}

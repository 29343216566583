import {AgeType} from './age-type.model';

export class BeneficiaryModel {
  ageType: AgeType;
  dateOfBirth: string;
  planIndex: number;
  selectedBenefits: {
    primary: number[];
    maternity: number[];
    dayToDay: number[];
  };
  currentHealthPlan: {
    provider: string;
    planOptions: string[];
    planName: string;
    renewalDate: string;
  };
  excessIndex: number;
  budgets: number[];
  selectedBudget: number;
  finalOptionsDetails: boolean;

  // temporary data set by the getFilteredBeneficiaries():
  index: number;
  isMain: boolean;

  constructor(
    ageType: AgeType,
    dateOfBirth: string = null,
    selectedBenefits: any = {primary: [], maternity: [], dayToDay: []},
    currentHealthPlan: any = {provider: '', planOptions: [], planName: '', renewalDate: ''},
    excessIndex: number = -1
  ) {
    this.ageType = ageType;
    this.dateOfBirth = dateOfBirth;
    this.planIndex = -1;
    this.selectedBenefits = selectedBenefits;
    this.currentHealthPlan = currentHealthPlan;
    this.excessIndex = excessIndex;
    this.finalOptionsDetails = false;
  }

}

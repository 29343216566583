import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../model/user.model';
import {Navigation} from '../model/navigation.model';
import {InsurancePlanOptions} from '../model/options.model';
import {DataTransferService} from '../core/services/data-transfer.service';
import {AgeType} from '../model/age-type.model';
import {BeneficiaryModel} from '../model/beneficiary.model';
import { RecommendedOptionDetails } from '../model/recommendedOptionDetails.model';
import { BenefitCategories } from '../model/benefit-categories.model';
import { HospitalDetails } from '../model/hospital-details.model';
import {NotifService} from '../core/services/notification.service';
import { SimilarOrFullComponent } from './similar-or-full/similar-or-full.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance-page.component.html',
  styleUrls: ['./insurance-page.component.scss']
})

export class InsurancePageComponent implements OnInit {
  mainUser: User;
  navigation: Navigation;
  options: InsurancePlanOptions;
  matTabs: any;
  currentTab: HTMLElement;
  childMinDate: any;
  childMaxDate: any;
  youngAdultMinDate: any;
  youngAdultMaxDate: any;
  adultMaxDate: any;
  adultMinDate: any;
  renewalMinDate: any;
  renewalMaxDate: any;
  mainUserAgeType: AgeType;
  getQuoteErrorMessage: string = null;
  recommendedPlanDetails: RecommendedOptionDetails[][] = [[]];
  benefitCategories: BenefitCategories[] = [];
  beneficiaryBenefitsPerCategories: BenefitCategories[][] = [[]];
  hospitalDetails: HospitalDetails[] = [];
  hospitalDetailsPerBeneficiary: HospitalDetails[][] = [];
  quoteCode: string;
  splitQuoteCode: string;
  minPrice: number;
  hideButton: boolean;
  @ViewChild(SimilarOrFullComponent, {static: true}) private similarOrFullComp: SimilarOrFullComponent;

  constructor(private dataTransferService: DataTransferService,
              private notifService: NotifService,
              private router: Router) {}

  ngOnInit() {
    this.options = new InsurancePlanOptions();
    this.getDatesRange();
    this.getPlanPackages();
    this.getProviders();
    this.mainUser = new User('', null, null, true, null, [], this.options);
    this.navigation = new Navigation(this.mainUser, 0, 5);
  }

  get self(): InsurancePageComponent {
    return this;
  }

  collectForm() {
    const userDataToPost = {
      bestForMe: null,
      type: '',
      username: '',
      insuranceRequestDTOS: null
    };
    userDataToPost.bestForMe = this.mainUser.mostAffordable;
    userDataToPost.type = this.mainUser.similarPlan ? 'SIMILAR' : 'FULL_REVIEW';
    userDataToPost.username = this.mainUser.name;
    userDataToPost.insuranceRequestDTOS = this.mainUser.beneficiaries.map((beneficiary) => this.collectBeneficiaryData(
      beneficiary,
      this.mainUser.samePlan,
      this.mainUser.mostAffordable,
      this.mainUser.beneficiaries[0]
    ));
    return userDataToPost;
  }

  insuranceFormSubmit() {
    const data = this.collectForm();
    let offeredOptions: RecommendedOptionDetails[] = [];
    this.recommendedPlanDetails = [];
    this.beneficiaryBenefitsPerCategories = [];
    this.hospitalDetailsPerBeneficiary = [];

    this.dataTransferService.sendCollectedData(data).subscribe(
      success => {
        this.quoteCode = success.quoteCode;
        this.splitQuoteCode = this.quoteCode.slice(0, 4) + ' ' + this.quoteCode.slice(4, 8);
        this.minPrice = success.minPrice;
        for (let index = +Object.keys(success.recommendedPlansByRequestId)[0];
                 index <= +Object.keys(success.recommendedPlansByRequestId)[Object.keys(success.recommendedPlansByRequestId).length - 1];
                 index++) {
          offeredOptions = [];
          success.recommendedPlansByRequestId[index].forEach((options) => {
            const objectHelper = options.recommendedPlanDetails;
            const tempOptionsDetailsObj =
              new RecommendedOptionDetails(objectHelper.provider, objectHelper.name, objectHelper.excess, objectHelper.price);
            offeredOptions.push(tempOptionsDetailsObj);
           });
          this.recommendedPlanDetails.push(offeredOptions);
        }
        for (let index = +Object.keys(success.recommendedPlansByRequestId)[0];
                 index <= +Object.keys(success.recommendedPlansByRequestId)[Object.keys(success.recommendedPlansByRequestId).length - 1];
                index++) {
          this.benefitCategories = [];
          this.hospitalDetails = [];
          success.recommendedPlansByRequestId[index].forEach((recommendedPlansByRequestIdElement) => {
            recommendedPlansByRequestIdElement.hospitals.forEach(hospital => {
              const tempHospitalDetail = new HospitalDetails(hospital.type, []);
              // HIGH_TECH_SEMI_PRIVATE HIGH_TECH_PRIVATE
              if (hospital.type === 'PUBLIC' || hospital.type === 'PRIVATE' ||
                (success.requestById[index] && success.requestById[index].packageType === 'PLATINUM' &&
                  (hospital.type === 'HIGH_TECH_SEMI_PRIVATE' || hospital.type === 'HIGH_TECH_PRIVATE')
                )
              ) {
                this.hospitalDetails.push(tempHospitalDetail);
              }
            });
            this.hospitalDetails = this.hospitalDetails.reduce((reducer, hospitalDetail) =>
              !reducer.some(hospital => hospital.type === hospitalDetail.type) ? [...reducer, hospitalDetail] : reducer, []);
            recommendedPlansByRequestIdElement.hospitals.forEach((hospital) => {
              this.hospitalDetails.forEach((hospitalDetails, k) => {
                if (hospital.type === hospitalDetails.type) {
                  if (hospital.type.includes('PRIVATE')) {
                    const concatValue =  hospital.value !== hospital.particular && hospital.particular !== null ?
                      hospital.value + ' ' + hospital.particular : hospital.value;
                    this.hospitalDetails[k].details.push(concatValue);
                  } else if (hospital.type === 'PUBLIC') {
                    this.hospitalDetails[k].details.push(hospital.value);
                  }
                }
              });
            });
            recommendedPlansByRequestIdElement.benefitCategories.forEach((benefitCategoriesElement) => {
              const tempBenefitCategories = new BenefitCategories(benefitCategoriesElement.categoryDTO.name, [] , null);
              this.benefitCategories.push(tempBenefitCategories);
            });
            this.benefitCategories = this.benefitCategories.reduce((reducer, benefitCategoriesCategory) =>
              !reducer.some(category =>
                category.categoryName === benefitCategoriesCategory.categoryName) ? [...reducer, benefitCategoriesCategory] : reducer, []);
            recommendedPlansByRequestIdElement.benefitCategories.forEach((benefitCategoriesElement) => {
              this.benefitCategories.forEach(benefitCategory => {
                if (benefitCategory.categoryName === benefitCategoriesElement.categoryDTO.name) {
                  benefitCategory.benefitsName.push(benefitCategoriesElement.benefitDTO.value);
                  benefitCategory.isUserSelected = benefitCategoriesElement.userSelectedCategory;
                }
              });
            });
          });
          this.hospitalDetailsPerBeneficiary .push(this.hospitalDetails);
          this.beneficiaryBenefitsPerCategories.push(this.benefitCategories);
        }
        if (!this.mainUser.similarPlan) {
          this.moveToTab(this.navigation.maxTabsNumber);
        }
        this.getQuoteErrorMessage = null;
      }, error => {
        this.notifService.error(error.message);
      }
    );
  }

  getTab(index) {
    this.matTabs = document.querySelectorAll('*[id^="mat-tab-content"]');
    for (const tab of this.matTabs) {
      if (tab.id.endsWith(index.toString())) {
        this.currentTab = tab;
      }
    }
    return this.currentTab;
  }

  moveToTab(tab) {
    if (tab >= 0 && tab <= this.navigation.maxTabsNumber) {
      this.navigation.selectedIndex = tab;
      this.getTab(this.navigation.selectedIndex).click();
    }
  }

  moveToNextTab() {
    this.moveToTab(this.navigation.selectedIndex + 1);
  }

  moveToPreviousTab() {
    this.moveToTab(this.navigation.selectedIndex - 1);
  }

  mustGoThroughPage(optionType) {
    return this.getFilteredBeneficiaries().some(person => this.specialOptionSelected(person, optionType));
  }

  checkSelectedOptions() {
    let selectTypes = 0;
    if (this.mustGoThroughPage('maternity')) {
      selectTypes = 1;
    }
    if (this.mustGoThroughPage('dayToDay')) {
      if (selectTypes === 1) {
        selectTypes = 2;
      } else {
        selectTypes = 3;
      }
    }
    return selectTypes;
  }

  backButtonHandler() {
    if (this.navigation.selectedIndex === 0) { this.router.navigate(['/']); }
    if (this.mainUser.similarPlan && this.navigation.selectedIndex === this.navigation.maxTabsNumber) {
      this.mainUser.similarPlan = null;
      this.similarOrFullComp.refresh();
      this.moveToTab(2);
    } else if (this.mainUser.mostAffordable && this.getCount(AgeType.ADULT) === 0 &&
               this.navigation.selectedIndex === this.navigation.maxTabsNumber) {
      this.moveToTab(0);
    } else if (!this.mainUser.similarPlan && this.navigation.selectedIndex === 1 &&
               this.mainUser.similarPlan !== null) {
      this.mainUser.similarPlan = null;
      this.similarOrFullComp.refresh();
      this.moveToTab(2);
    } else if (this.navigation.selectedIndex === this.navigation.maxTabsNumber ) {
      const backStepValue = this.checkSelectedOptions();
      if (backStepValue === 0) {
        this.moveToTab(2);
      } else if (backStepValue === 1) {
        this.moveToTab(3);
      } else if (backStepValue === 2) {
        this.moveToPreviousTab();
      } else if (backStepValue === 3) {
        this.moveToPreviousTab();
      }
    } else if (this.navigation.selectedIndex === this.navigation.maxTabsNumber - 1 && this.checkSelectedOptions() === 3) {
      this.moveToTab(2);
    }  else {
      this.moveToPreviousTab();
    }
  }

  getDatesRange() {
    this.dataTransferService.getAgeIntervals().subscribe(
      success => {
        this.youngAdultMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - success.YOUNG_ADULT.first));
        this.adultMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - success.ADULT.first));
        this.childMinDate = new Date(new Date().setFullYear(
          this.youngAdultMaxDate.getFullYear(),
          this.youngAdultMaxDate.getMonth(),
          this.youngAdultMaxDate.getDate() + 1));
        this.youngAdultMinDate = new Date(new Date().setFullYear(
          this.adultMaxDate.getFullYear(),
          this.adultMaxDate.getMonth(),
          this.adultMaxDate.getDate() + 1));
      },
      error => this.notifService.error(error.error)
    );
    this.childMaxDate = new Date();
    this.adultMinDate = new Date(new Date().setFullYear(new Date().getFullYear() - 130));
    this.renewalMinDate = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
    this.renewalMaxDate = new Date(new Date().getTime() + 42 * 24 * 60 * 60 * 1000);
  }

  // extracts options of certain types from benefits and sorts the options by name length
  extractOption(data, type) {
    const shorterCompName = (a, b) => a.name.length - b.name.length;
    return data.filter(element => element.type === type).sort(shorterCompName);
  }

  getPlanPackages() {
    this.dataTransferService.getPlanPackages().subscribe(success => {
      this.options.planPackages = success.map(
        planPackage => ({
          ...planPackage,
          excesses: planPackage.excesses.
            filter(excess => excess.excess !==  -1).
            map(excess => excess.excess),
          budgets: planPackage.budgets.map(budget => budget.annualBudget),
          benefits: {
            primaryOptions: this.extractOption(planPackage.categories, 'PRIMARY'),
            maternityOptions: this.extractOption(planPackage.categories, 'MATERNITY'),
            dayToDayOptions: this.extractOption(planPackage.categories, 'DAY_TO_DAY')
          }
        })
      );
    },
      error => this.notifService.error(error.message));
  }

  getProviders() {
    this.dataTransferService.getProviders().subscribe(success => {
      success.forEach(provider => {
        this.options.providers = [...this.options.providers, provider.replace(/[_]/g, ' ')];
      });
    },
      error => this.notifService.error(error.message));
  }

  getAllBeneficiaries() {
    let previousType = null;
    let index; // initial value doesn't matter
    this.mainUser.beneficiaries.
      // populate temporary data:
      forEach(person => {
        person.index = previousType === person.ageType ? ++index : index = 0;
        person.isMain = this.mainUserAgeType === person.ageType && index === 0;
        previousType = person.ageType;
      });
    return this.mainUser.beneficiaries;
  }

  /** @return mainUser, if samePlan is selected
   * all the adults, if same plan is not selected, but most affordable is
   * all beneficiaries, if same plan is not selected and most affordable is not selected
   */
  getFilteredBeneficiaries() {
    return this.getAllBeneficiaries().
      filter((person, i) =>
        (this.mainUser.samePlan ? i === 0 : true) &&
        (!this.mainUser.mostAffordable || person.ageType === AgeType.ADULT)
      );
  }

  totalNumberOfBeneficiaries() {
    return this.mainUser.beneficiaries.length;
  }

  getCount(type) {
    return this.mainUser.beneficiaries.filter(person => person.ageType === type).length;
  }

  cannotAdvance(optionType) {
    if (!this.totalNumberOfBeneficiaries()) { return true; }
    return this.getFilteredBeneficiaries().some(person => this.shouldHaveValue(person, optionType) && !this.hasValue(person, optionType));
  }

  // ALL THESE FOR insertBeneficiaryName //

  private getInsuredNameBase(beneficiary: BeneficiaryModel, personMap) {
    if ((beneficiary.index === 0) && beneficiary.isMain) {
      return personMap.you;
    } else {
      return personMap[beneficiary.ageType] + ' ' + (beneficiary.index + 1);
    }
  }

  private getInsuredName(beneficiary: BeneficiaryModel) {
    return this.getInsuredNameBase(beneficiary, {adult: 'adult', youngAdult: 'young adult', child: 'child', you: 'you'});
  }

  private getInsuredNameUpper(beneficiary: BeneficiaryModel) {
    return this.getInsuredNameBase(beneficiary, {adult: 'Adult', youngAdult: 'Young adult', child: 'Child', you: 'You'});
  }

  private changeStarTo(text, who, Who) {
    const index = text.indexOf('*');
    if (index >= 0) {
      if (index === 0) {
        return text.replace('*', Who);
      } else {
        return text.replace('*', who);
      }
    } else {
      throw new Error('Put a star in the string!');
    }
  }

  insertBeneficiaryName(beneficiary: BeneficiaryModel, tYour, tElse) {
    if (tElse == null) {
      tElse = tYour;
    }
    const who = this.getInsuredName(beneficiary);
    const Who = this.getInsuredNameUpper(beneficiary);
    const text = beneficiary.isMain ? tYour : tElse;
    return this.changeStarTo(text, who, Who);
  }

  // beneficiary functions

  // sets a plan index for a beneficiary
  setPlanPackage(beneficiary: BeneficiaryModel, index: number) {
    let justChanged = false;
    if (index !== beneficiary.planIndex) {
      justChanged = true;
    }
    beneficiary.planIndex = index;
    if (justChanged) {
      beneficiary.excessIndex = -1;
      this.getBudgets(beneficiary);
    }
  }

  // returns the package plan selected for a beneficiary
  getPlanPackage(beneficiary: BeneficiaryModel) {
    return beneficiary.planIndex >= 0 ? this.options.planPackages[beneficiary.planIndex] : null;
  }

  // selects and deselects an option from benefits
  toggleOption(beneficiary: BeneficiaryModel, optionType, option) {
    if (this.isOptionSelected(beneficiary, optionType, option)) {
      // remove option:
      beneficiary.selectedBenefits[optionType].splice(beneficiary.selectedBenefits[optionType].indexOf(option), 1);
      // remove special options if maternity or day to day is removed from primary:
      if (optionType === 'primary') {
        const optionName = this.getPlanPackage(beneficiary).benefits.primaryOptions.find(special => special.id === option).name;
        if (Object.values(this.options.optionNames).includes(optionName)) {
          const specialType = Object.keys(this.options.optionNames).find(key => this.options.optionNames[key] === optionName);
          beneficiary.selectedBenefits[specialType] = [];
        }
      }
    } else {
      // add option:
      beneficiary.selectedBenefits[optionType] = [...beneficiary.selectedBenefits[optionType], option];
    }
  }

  isOptionSelected(beneficiary: BeneficiaryModel, optionType, option) {
    return beneficiary.selectedBenefits[optionType].includes(option);
  }

  // checks if day-to-day or maternity option are selected
  specialOptionSelected(beneficiary: BeneficiaryModel, optionType) {
    const name = this.options.optionNames[optionType];
    if (!name) { return true; }
    const planPackage = this.getPlanPackage(beneficiary);
    if (!planPackage) {return false; }
    const element = planPackage.benefits.primaryOptions.find(option => name === option.name);
    const id = element ? element.id : -1;
    return id > 0 ? this.isOptionSelected(beneficiary, 'primary', id) : false;
  }

  hasValue(beneficiary: BeneficiaryModel, fieldName) {
    switch (fieldName) {
      case 'dateOfBirth':
        return beneficiary.dateOfBirth;
      case 'planPackage':
        return beneficiary.planIndex !== -1;
      case 'primary':
        return beneficiary.selectedBenefits.primary.length;
      case 'maternity':
        return beneficiary.selectedBenefits.maternity.length;
      case 'dayToDay':
        return beneficiary.selectedBenefits.dayToDay.length;
      case 'provider':
        return beneficiary.currentHealthPlan.provider;
      case 'plan':
        return beneficiary.currentHealthPlan.planName;
      case 'renewalDate':
        return beneficiary.currentHealthPlan.renewalDate;
      case 'excess':
        return beneficiary.excessIndex !== -1;
    }
  }

  shouldHaveValue(beneficiary: BeneficiaryModel, fieldName) {
    switch (fieldName) {
      case 'planPackage':
      case 'primary':
      case 'provider':
      case 'plan':
      case 'renewalDate':
      case 'dateOfBirth':
          return true;
      case 'maternity':
      case 'dayToDay':
          return this.specialOptionSelected(beneficiary, fieldName);
      case 'excess':
        return this.hasExcesses(beneficiary);
    }
  }

  // checks if the plan has excesses
  hasExcesses(beneficiary: BeneficiaryModel) {
    if (beneficiary.planIndex >= 0) {
      return this.getPlanPackage(beneficiary).excesses.length > 0;
    }
    return false;
  }

  // returns excesses available for a plan package
  getExcesses(beneficiary: BeneficiaryModel) {
    if (beneficiary.planIndex >= 0) {
      return this.getPlanPackage(beneficiary).excesses;
    }
    return [];
  }

  // sets excess for a beneficiary
  setExcess(beneficiary: BeneficiaryModel, index) {
    if (((index === 0 || index === 1) && (beneficiary.selectedBudget === beneficiary.budgets[0])) ||
      (index === 0 && beneficiary.selectedBudget === beneficiary.budgets[1])) {
      return false;
    }
    beneficiary.excessIndex = index;
  }

  // checks if the plan has budgets
  hasBudgets(beneficiary: BeneficiaryModel) {
    if (beneficiary.planIndex >= 0) {
      return this.getPlanPackage(beneficiary).budgets.length > 0;
    }
    return false;
  }

  // returns budgets available for a plan package
  getBudgets(beneficiary: BeneficiaryModel) {
    if (beneficiary.planIndex >= 0) {
      beneficiary.budgets = this.getPlanPackage(beneficiary).budgets;
      beneficiary.selectedBudget = beneficiary.budgets[0];
    }
    return [];
  }

  // sets annualBudget for a beneficiary;
  setBudget(beneficiary: BeneficiaryModel, selectedValue) {
    if (selectedValue === beneficiary.budgets[0] || selectedValue === beneficiary.budgets[2]) {
      beneficiary.selectedBudget = selectedValue;
    } else {
      beneficiary.selectedBudget = beneficiary.budgets[1];
    }
    beneficiary.excessIndex = -1;
  }

  // sets the renewal date for existing plan
  setRenewalDate(beneficiary: BeneficiaryModel, event) {
    if (event.target.value !== undefined) {
      event.target.value.setHours(20);
      beneficiary.currentHealthPlan.renewalDate = event.target.value.toISOString();
    }
  }

  collectBeneficiaryData(beneficiary: BeneficiaryModel, samePlan, mostAffordable, firstObject) {
    const map = {};
    map[AgeType.ADULT] = 'ADULT';
    map[AgeType.YOUNGADULT] = 'YOUNG_ADULT';
    map[AgeType.CHILD] = 'CHILDREN';

    const beneficiaryData = {
      dateOfBirth: beneficiary.dateOfBirth,
      principal: beneficiary.isMain,
      childType: null,
      beneficiaryType: map[beneficiary.ageType],
      annualBudget: null,
      categories: null,
      excess: null,
      packageType: null,
      renewalDate: null,
      similarPlanName: null,
      similarProviderType: null
    };

    if (beneficiary.ageType === 'child') {
      switch (true) {
        case (beneficiary.index === 0):
          beneficiaryData.childType = 'FIRST';
          break;
        case (beneficiary.index === 1):
          beneficiaryData.childType = 'SECOND';
          break;
        case (beneficiary.index === 2):
          beneficiaryData.childType = 'THIRD';
          break;
        case (beneficiary.index >= 3):
          beneficiaryData.childType = 'FOURTH';
      }
    } else {
      beneficiaryData.childType = null;
    }

    // if mostAffordable, we should not send any data but the dateOfBirth for young and children
    if (!mostAffordable || beneficiary.ageType === AgeType.ADULT) {
      // if samePlan, we have to copy all data but dateOfBirth from the first object
      let beneficiaryObj = beneficiary;
      if (samePlan) {
        beneficiaryObj = firstObject;
      }

      if (this.mainUser.similarPlan) {
        beneficiaryData.annualBudget = null;
        beneficiaryData.categories = null;
        beneficiaryData.excess = null;
        beneficiaryData.packageType = null;
        beneficiaryData.renewalDate = beneficiaryObj.currentHealthPlan.renewalDate ? beneficiaryObj.currentHealthPlan.renewalDate : null;
        beneficiaryData.similarPlanName = beneficiaryObj.currentHealthPlan.planName ? beneficiaryObj.currentHealthPlan.planName : null;
        beneficiaryData.similarProviderType =
          beneficiaryObj.currentHealthPlan.provider ? beneficiaryObj.currentHealthPlan.provider.replace(/[ ]/g, '_') : null;
      } else {
        beneficiaryData.annualBudget = beneficiaryObj.selectedBudget ? beneficiaryObj.selectedBudget : beneficiaryObj.budgets[0];
        beneficiaryData.categories = [
          ...beneficiaryObj.selectedBenefits.primary.map(id =>
            this.getPlanPackage(beneficiaryObj).benefits.primaryOptions.find(option => id === option.id)),
          ...beneficiaryObj.selectedBenefits.maternity.map(id =>
            this.getPlanPackage(beneficiaryObj).benefits.maternityOptions.find(option => id === option.id)),
          ...beneficiaryObj.selectedBenefits.dayToDay.map(id =>
            this.getPlanPackage(beneficiaryObj).benefits.dayToDayOptions.find(option => id === option.id)),
        ];
        beneficiaryData.excess = beneficiaryObj.excessIndex < 0 ? -1 : this.getPlanPackage(beneficiaryObj).excesses[beneficiaryObj.excessIndex];
        beneficiaryData.packageType = this.getPlanPackage(beneficiaryObj).type;
        beneficiaryData.renewalDate = beneficiaryObj.currentHealthPlan.renewalDate ? beneficiaryObj.currentHealthPlan.renewalDate : null;
        beneficiaryData.similarPlanName = beneficiaryObj.currentHealthPlan.planName ? beneficiaryObj.currentHealthPlan.planName : null;
        beneficiaryData.similarProviderType =
          beneficiaryObj.currentHealthPlan.provider ? beneficiaryObj.currentHealthPlan.provider.replace(/[ ]/g, '_') : null;
      }
    }
    return beneficiaryData;
  }

  hideBackButton(emailFormSubmitted) {
    emailFormSubmitted ? this.hideButton = true : this.hideButton = false;
  }
}

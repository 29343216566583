import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../core/services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public isMenuCollapsed = true;
  isOnAdminPage = false;
  isOnHomePage = false;
  userName: string;
  items = [
    { main: "Who we are", path: "https://hcc.ie/who-we-are/" },
    {
      main: "Health insurers",
      path: "https://hcc.ie/#hcchi",
      submenus: [
        {
          main: "Irish Life Health",
          path: "https://hcc.ie/irish-life-health/",
        },
        { main: "Laya Health Care", path: "https://hcc.ie/laya-health-care/" },
        { main: "VHI", path: "https://hcc.ie/vhi/" },
      ],
    },
    {
      main: "Helpful info",
      path: "https://hcc.ie/helpful-info/",
    },
    { main: "Blog", path: "https://news.hcc.ie/" },
    { main: "FAQ", path: "https://hcc.ie/faq/" },
    { main: "Contact", path: "https://hcc.ie/contact/" },
    [
      { filter: "employers-insurance", main: "For People", path: "insurance" },
      { filter: "", main: "For Employers", path: "employers-insurance" },
    ],
  ];
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.getUserName();
    this.checkCurrentRoute();
  }

  checkCurrentRoute() {
    const url = this.router.url;
    if (url === "/") {
      this.isOnHomePage = true;
    } else if (url.includes("/admin")) {
      this.isOnAdminPage = true;
    }
  }
  getItem(item) {
    if (!Array.isArray(item)) {
      return item;
    }
    const url = this.router.url;
    return item.find((i) => url.includes(i.filter));
  }

  toggleCollapsedMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  collapseMenu() {
    this.isMenuCollapsed = true;
  }

  getUserName() {
    this.userName = localStorage.getItem("currentUserName")
      ? localStorage.getItem("currentUserName")
      : sessionStorage.getItem("currentUserName");
  }

  isManager() {
    const storedType = localStorage.getItem("currentUserType")
      ? localStorage.getItem("currentUserType")
      : sessionStorage.getItem("currentUserType");
    return storedType === "MANAGER";
  }
}

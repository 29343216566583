import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { AuthenticationService } from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  loginError: string = null;
  formSubmitted = false;
  hidePassword = true;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private notificationService: NotifService) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email : new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  get loginFormControls() { return this.loginForm.controls; }

  loginSubmit() {
    this.formSubmitted = true;
    if (this.loginForm.valid) {
      const emailValue = this.loginForm.get('email').value;
      const passwordValue = this.loginForm.get('password').value;
      this.authService.login(emailValue, passwordValue).subscribe(
        success => this.router.navigate(['/admin']),
        error => this.notificationService.error(error.error)
      );
    }
  }

  checkRememberMeValue(rememberMeValue) {
    this.authService.rememberMe = !rememberMeValue;
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {Router} from '@angular/router';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  forgotPassError: string;
  forgotPassSucccess: string;
  formSubmitted = false;
  constructor(private httpService: UserService,
              private router: Router,
              private notificationService: NotifService) {
  }

  ngOnInit() {
    this.forgotPassForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  get forgotFormControls() { return this.forgotPassForm.controls; }

  forgotPassSubmit() {
    this.formSubmitted = true;
    if (this.forgotPassForm.valid) {
      const emailValue = this.forgotPassForm.get('email').value;
      this.httpService.forgotPassword(emailValue).subscribe(
        () => {
          this.notificationService.success('Email sent successfully.');
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2000);
        },
        error => this.notificationService.error(error.error)
      );
    }
  }
}

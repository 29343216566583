import {NgModule} from '@angular/core';
import {RegisterPageComponent} from './auth/register-page/register-page.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpDomainInterceptor} from '../core/interceptors/httpDomainInterceptor';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {LoginPageComponent} from './auth/login-page/login-page.component';
import {RouterModule} from '@angular/router';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './auth/change-password/change-password.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {AdminPageComponent} from './admin-page/admin-page.component';
import {SharedModule} from '../shared/shared.module';
import {MatFormFieldModule, MatInputModule, MatTableModule, MatTabsModule, MatIconModule, MatSelectModule} from '@angular/material';
import {MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import {MatPaginatorModule} from '@angular/material/paginator';
import {QuotePageComponent} from './admin-page/quote-page/quote-page.component';
import {HttpTokenInterceptor} from '../core/interceptors/httpTokenInterceptor';
import {PaginatorComponent} from './admin-page/paginator/paginator.component';
import {UserQuoteCardComponent} from './admin-page/quote-page/user-quote-card/user-quote-card.component';
import {SettingsPageComponent} from './admin-page/settings-page/settings-page.component';
import {ManagerComponent} from './admin-page/manager/manager.component';
import {AdminUsersTabComponent} from './admin-page/admin-users-tab/admin-users-tab.component';
import {AdminEmployersTabComponent} from './admin-page/admin-employers-tab/admin-employers-tab.component';
import {OffersPageComponent} from './admin-page/quote-page/offers-page/offers-page.component';
import {ProposePlanComponent} from './admin-page/quote-page/propose-plan/propose-plan.component';
import { EmployerQuoteCardComponent } from './admin-page/quote-page/employer-quote-card/employer-quote-card.component';
import { PurchaseQuoteComponent } from './admin-page/quote-page/purchase-quote/purchase-quote.component';
import { CostManagerComponent } from './admin-page/cost-manager/cost-manager.component';
import { ActivateAccountComponent } from './auth/activate-account/activate-account.component';

@NgModule({
  declarations: [
    RegisterPageComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AdminPageComponent,
    QuotePageComponent,
    PaginatorComponent,
    UserQuoteCardComponent,
    SettingsPageComponent,
    AdminUsersTabComponent,
    AdminEmployersTabComponent,
    OffersPageComponent,
    ProposePlanComponent,
    EmployerQuoteCardComponent,
    PurchaseQuoteComponent,
    ManagerComponent,
    CostManagerComponent,
    ActivateAccountComponent
  ],
  imports: [
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    CommonModule,
    SharedModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule
  ],
  exports: [],
  providers: [  { provide: HTTP_INTERCEPTORS, useClass: HttpDomainInterceptor, multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }]
})

export class AdminModule {

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {RegisterUserRequest} from '../../model/register-request.model';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private httpClient: HttpClient) { }

  registerUser(data: RegisterUserRequest): Observable<any> {
    return this.httpClient.post<any>('/registration', data);
  }

  forgotPassword(email: string): Observable<any> {
    const request = encodeURIComponent(email);
    return this.httpClient.post(`/forgot-password?email=${request}`, {} );
  }

  changePassword(data): Observable<any> {
    return this.httpClient.put<any>('/change-password', data);
  }

  changeNameAndEmail(data): Observable<any> {
    return this.httpClient.put<any>(`/change-name`, data);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playbutton',
  templateUrl: './playbutton.component.html',
  styleUrls: ['./playbutton.component.scss']
})
export class PlaybuttonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

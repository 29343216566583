export class RegisterUserRequest {
  confirmationPassword: string;
  email: string;
  fullName: string;
  password: string;

  constructor(confirmationPassword: string, email: string, fullName: string, password: string) {
    this.confirmationPassword = confirmationPassword;
    this.email = email;
    this.fullName = fullName;
    this.password = password;
  }
}

export class ChangePasswordRequest {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;

  constructor(confirmNewPassword: string, newPassword: string, oldPassword: string) {
    this.confirmNewPassword = confirmNewPassword;
    this.newPassword = newPassword;
    this.oldPassword = oldPassword;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {EmployerQuoteModel} from '../../../../model/employer-quote.model';

@Component({
  selector: 'app-employer-quote-card',
  templateUrl: './employer-quote-card.component.html',
  styleUrls: ['./employer-quote-card.component.scss']
})
export class EmployerQuoteCardComponent implements OnInit {
  @Input() employerQuote: EmployerQuoteModel;
  isPlanProposed = false;
  showProposePlanDialog = false;
  constructor() { }

  ngOnInit() {
    if (this.employerQuote.proposedPlanName) {
      this.isPlanProposed = true;
    }
  }

  hideProposePlanDialog() {
    this.showProposePlanDialog = false;
  }

  showProposedPlanName(proposedPlanValues) {
    this.employerQuote.proposedPlanProvider = proposedPlanValues.providerType.replace(/[_]/g, ' ');
    this.employerQuote.proposedPlanName = proposedPlanValues.planName;
    this.isPlanProposed = true;
  }
}

import {Component, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() quotesCount: number;
  @Output() changePageClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  pageChanged(event) {
    this.changePageClicked.emit(event);
  }
}

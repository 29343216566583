import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataTransferService} from '../../../core/services/data-transfer.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  accountActivated = null;

  constructor(private router: Router, private dataTransferService: DataTransferService) { }

  ngOnInit() {
    const currentUrl = this.router.url.split('/');
    this.dataTransferService.activateAccount(currentUrl[currentUrl.length - 1], currentUrl[currentUrl.length - 2]).subscribe(
      () => this.accountActivated = true,
      () => this.accountActivated = false
    );
  }
}

import {Component, OnInit, Input} from '@angular/core';
import {InsurancePageComponent} from '../insurance-page.component';
import {User} from '../../model/user.model';
import { BeneficiaryModel } from '../../model/beneficiary.model';

@Component({
  selector: 'app-ins-page-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
  @Input() parent: InsurancePageComponent;
  @Input() optionType: string;
  mainUser: User;

  constructor() { }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
  }

  getOptionName() {
    const optionName = this.mainUser.options.optionNames[this.optionType];
    return optionName ? optionName.toLowerCase() : '';
  }

  getBenefits(beneficiary: BeneficiaryModel) {
    return this.parent.getPlanPackage(beneficiary) ? this.parent.getPlanPackage(beneficiary).benefits[this.optionType + 'Options'] : [];
  }
}

import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {DataTransferService} from '../core/services/data-transfer.service';
import {Router} from '@angular/router';
import {NotifService} from '../core/services/notification.service';

@Component({
  selector: 'app-employers-insurance',
  templateUrl: './employers-insurance.component.html',
  styleUrls: ['./employers-insurance.component.scss']
})
export class EmployersInsuranceComponent implements OnInit {
  employersForm: FormGroup;
  providerOptions = [];
  planOptions = [];
  submitted = false;
  selectedIndex = 0;
  stepImgPath: string;
  errorMessage: string;
  maxTabsNumber = 1;
  currentTab: HTMLElement;
  matTabs: any;
  employeesNumbers = [];
  summary = {
    fullName: '',
    email: '',
    phoneNo: '',
    companyName: '',
    noEmployees: '',
    insurer: '',
    plan: '',
    renewalDate: null,
    info: ''
  };
  renewalMinDate;
  renewalMaxDate;
  employeeNumbersMap = {SIZE_1_TO_10: '1-10',
                        SIZE_10_TO_50: '10-50',
                        SIZE_50_TO_100: '50-100',
                        SIZE_100_TO_200: '100-200',
                        SIZE_200_OR_MORE: '200+'
                      };

  constructor(private dataTransferService: DataTransferService,
              private router: Router,
              private notifService: NotifService) { }

  ngOnInit() {
    this.employersForm = new FormGroup({
      fullName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNo: new FormControl('', [Validators.required, Validators.pattern('[0-9].{2,}')]),
      companyName: new FormControl('', [Validators.required, Validators.pattern('.{2,}')]),
      noEmployees: new FormControl('', [Validators.required]),
      insurer: new FormControl('', [Validators.required]),
      plan: new FormControl('', [Validators.required]),
      renewalDate: new FormControl('', [Validators.required]),
      info: new FormControl('')
    });
    this.submitted = false;
    if (sessionStorage.getItem('employers-form-advanced') === 'true') {
      this.selectedIndex = 1;
      this.submitted = true;
      this.summary = JSON.parse(sessionStorage.getItem('employers-form-details'));
      this.employersForm.setValue(this.summary);
    } else { this.selectedIndex = 0; }

    this.stepImgPath = '../../assets/employers-step.svg';
    this.renewalMinDate = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
    this.renewalMaxDate = new Date(new Date().getTime() + 42 * 24 * 60 * 60 * 1000);
    this.getEmployersData();
  }

  get employersFormC() { return this.employersForm.controls; }

  getEmployersData() {
    this.dataTransferService.getEmployeesNumbers().subscribe(
      success => this.employeesNumbers = success,
      error => this.notifService.error(error.message)
    );

    this.dataTransferService.getProviders().subscribe(success => {
      success.forEach(provider => {
        this.providerOptions = [...this.providerOptions, provider.replace(/[_]/g, ' ')];
      });
    }, error => this.notifService.error(error.message));
  }

  getSelectedProviderPlans(event) {
    const provider = event.value.replace(/[ ]/g, '_');
    this.dataTransferService.getProviderPlans(provider).subscribe(success => {
      this.planOptions = success;
    }, error => this.notifService.error(error.message));
  }

  getTab(index) {
    this.matTabs = document.querySelectorAll('*[id^="mat-tab-content"]');
    for (const tab of this.matTabs) {
      if (tab.id.endsWith(index.toString())) {
        this.currentTab = tab;
      }
    }
    return this.currentTab;
  }

  moveToTab(tab) {
    if (tab >= 0 && tab <= this.maxTabsNumber) {
      this.selectedIndex = tab;
      this.getTab(this.selectedIndex).click();
    }
  }

  moveToPreviousTab() {
    this.moveToTab(this.selectedIndex - 1);
    this.getSelectedProviderPlans({value: this.summary.insurer});
  }

  moveToNextTab() {
    this.moveToTab(this.selectedIndex + 1);
    this.submitted = true;
    this.summary = this.employersForm.value;
    sessionStorage.setItem('employers-form-advanced', 'true');
    sessionStorage.setItem('employers-form-details', JSON.stringify(this.summary));
  }

  getSessionStorageValue(key) {
    return sessionStorage.getItem(key);
  }

  employersFormSubmit() {
    this.employersForm.value.insurer = this.employersForm.value.insurer.replace(/[ ]/g, '_');
    this.dataTransferService.employerFormSubmit(this.employersForm.value).subscribe(
      () => sessionStorage.setItem('employers-form-submitted', 'true'),
      error => this.notifService.error(error.message)
    );
    this.employersForm.value.insurer = this.employersForm.value.insurer.replace(/[ ]/g, '_');
  }
}

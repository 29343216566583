import {BeneficiaryModel} from './beneficiary.model';
import { InsurancePlanOptions } from './options.model';

export class User {
  name: string;
  hasHealthInsurance: boolean;
  hasHealthInsuranceValue: string; // this is the display value for the previous
  mostAffordable: boolean;
  samePlan: boolean;
  similarPlan: boolean;
  beneficiaries: BeneficiaryModel[];
  options: InsurancePlanOptions;

  constructor(name, hasHealthInsurance, mostAffordable, samePlan, similarPlan, beneficiaries, options) {
    this.name = name;
    this.hasHealthInsurance = hasHealthInsurance;
    this.mostAffordable = mostAffordable;
    this.samePlan = samePlan;
    this.similarPlan = similarPlan;
    this.beneficiaries = beneficiaries;
    this.options = options;
  }


}

import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-introvideo',
  templateUrl: './introvideo.component.html',
  styleUrls: ['./introvideo.component.scss']
})
export class IntrovideoComponent implements OnInit {
  show = false;
  videoId: string;
  videoUrl: string;

  ngOnInit() {
    this.videoId = '463878123';
    this.videoUrl = 'https://player.vimeo.com/video/' + this.videoId + '?autoplay=1';
  }

  showVideo() {
    this.show = true;
  }
  hideVideo() {
    this.show = false;
  }
}

@Pipe({ name: 'safe' })

export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import {PaginatorComponent} from '../paginator/paginator.component';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import {DataTransferService} from '../../../core/services/data-transfer.service';
import {FormatDateService} from '../../../core/services/format-date.service';
import {FormControl, FormGroup} from '@angular/forms';
import {NotifService} from '../../../core/services/notification.service';

@Component({
  selector: 'app-admin-employers-tab',
  templateUrl: './admin-employers-tab.component.html',
  styleUrls: ['./admin-employers-tab.component.scss']
})
export class AdminEmployersTabComponent implements OnInit, AfterViewInit {
  @ViewChild(PaginatorComponent, {static: true}) paginator: PaginatorComponent;
  tableColumnsNames = ['fullName', 'email', 'phoneNo', 'companyName', 'noEmployees', 'plan', 'createdOn', 'status'];
  dataSource: MatTableDataSource<any> = null;
  quoteStatuses = ['QUOTE', 'N T U', 'PURCHASED'];
  employersQuotesData: any;
  employersQuotesCount: number;
  selectForm: FormGroup;

  constructor(private router: Router,
              private dataTransferService: DataTransferService,
              private formatDateService: FormatDateService,
              private notifService: NotifService) { }

  ngOnInit() {
    this.dataTransferService.getEmployerQuotesCount().subscribe(
      success => this.employersQuotesCount = success,
      error => this.notifService.error(error.message)
    );
    this.getEmployerQuotesData(20, 0);
    this.selectForm = new FormGroup({
      selectOption: new FormControl()
    });
  }

  ngAfterViewInit() {
    this.setPaginator();
  }

  getEmployerQuotesData(pageSize, pageNumber) {
    this.dataTransferService.getEmployerQuotesData(pageSize, pageNumber).subscribe(employerQuotes => {
      this.employersQuotesData = employerQuotes.map( quoteData => ({
        ...quoteData,
        createdOn: this.formatDateService.changeDateFormat(quoteData.createdOn),
        renewalDate: this.formatDateService.changeDateFormat(quoteData.renewalDate),
        status: quoteData.status === 'NTU' ? 'N T U' : quoteData.status.replace('_', ' '),
        noEmployees: quoteData.noEmployees.substr(5, quoteData.noEmployees.length).replace('_TO_', ' - ').replace('_OR_MORE', ' or more')
      }));
      this.dataSource = new MatTableDataSource(this.employersQuotesData);
    }, error => this.notifService.error(error.message));
  }

  setPaginator() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator.paginator;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  filterByStatus(event) {
    this.dataSource.filter = event.value.trim().toLowerCase();
  }

  openQuotePage(row) {
    this.router.navigate([`admin/${row.id}`]);
  }

  pageChanged(event) {
    this.getEmployerQuotesData(event.pageSize, event.pageIndex);
    this.selectForm.reset();
  }

}

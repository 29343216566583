import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  @Input() home = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  slug: string = null;

  hardcodedContent = {
    'covid-19': {
      title: 'HealthCare Compare launches in Ireland',
      created: '26/05/2020',
      content: `At HCC.ie, we want to help you find a health insurance package that truly works for you and your family.
        <br />
        The healthcare market in Ireland is stifled by inertia. Finding the right plan is complex, with people often being reluctant to
         change plans for fear of losing out on essential health coverage – and we’re passionate about helping individuals and families
          make the most of the policies and benefits available.
        <br />
        <br />
        A Simpler Way to Find the Right Plan
        <br />
        There are over 320 policies currently available in the Irish market. Not only are these constantly changing, but new ones continue
         to be introduced – making it difficult to know whether you’ve found the right one. To add to this complexity, there are a number
          of common misconceptions about switching, with many people believing they will lose out if they change providers.
          <br/>
          With our new online tool, paired with advice from our insurance experts, you can make choosing the right health insurance plan simple.
           Our easy-to-use platform lets you choose from a number of detailed priorities and indicate which matter most to you and your loved
            ones – with everything from gym membership to maternity, and from cardiac care to counselling.
        <br />
        <br />
        Expert Insurance Advice
        <br />
     No one wants to put their health at risk with the wrong insurance plan. With advice from our experts, you can get visibility into the
      market and ensure you’re taking the right steps to protect yourself and your family, while keeping costs low.
        <br />
     The cheapest plan isn’t always the most cost-effective. There are multiple variables you need to consider, and our insurance
      experts can help you make the right decision for you.
        <br />
       With our no-obligation quotes you can select the priorities that matter most to you, including the level of cover you’d like, your budget,
        and your ideal excess payments – and our team at HCC will take care of the rest.
        <br />
        Just click ‘Get a Quote’ to get started, and get on your way to healthcare peace of mind.`,
      image: 'corona.png'
    }
  };

  ngOnInit() {
    this.slug = this.route.snapshot.paramMap.get('slug');
  }
  keys() {
    return Object.keys(this.hardcodedContent);
  }
  getExcerpt(text, wordCount = 20) {
    return text.split(' ').slice(0, wordCount).join(' ') + '...';
  }

}

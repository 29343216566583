import {
  Component,
  //OnInit,
  Input,
} from "@angular/core";
// import { Router } from "@angular/router";

@Component({
  selector: "app-headermenuitem",
  templateUrl: "./headermenuitem.component.html",
  styleUrls: ["./headermenuitem.component.scss"],
})
//implements OnInit
export class HeadermenuitemComponent {
  @Input() item: any;
  @Input() isOnHomePage: boolean;
  isBenefitsLinkActive: boolean;
  constructor() {} // private router: Router

  //ngOnInit() {
  // this.router.url.includes('/benefits') ? this.isBenefitsLinkActive = true : this.isBenefitsLinkActive = false;
  //}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maternity-benefits',
  templateUrl: './maternity-benefits.component.html',
  styleUrls: ['./maternity-benefits.component.scss']
})
export class MaternityBenefitsComponent implements OnInit {

  data = [
    {
      benefit: 'GentleBirth App',
      description: `All Irish Life Health members have unlimited access for one year to the GentleBirth app. This app provides everything
      you need for a more relaxed and positive pregnancy, birth, and parenting journey.`,
      type: `Outpatient/other maternity benefit/personalised package.</div>`
    },
    {
      benefit: 'Dietician or Nutritionist Consultation',
      description: `This benefit allows you to claim contributions towards the cost of attending a nutritionist or a dietician consultation.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'Antenatal Classes',
      description: `With this benefit you can claim contributions towards the cost of antenatal classes provided by a midwife.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'Pre/Postnatal Yoga and Pilates',
      description: `This benefit allows you to claim back some of the costs of pregnancy yoga, pregnancy pilates, baby yoga and baby pilates.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'Acupuncturist',
      description: `With this benefit you can claim contributions towards the cost of attending an acupuncturist.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'UMamma',
      description: `This benefit gives you a point of sale discount on holistic pre and postnatal treatments at the UMamma Sanctuary.`,
      type: `Member benefit.`
    },
    {
      benefit: '3D and 4D Maternity Scans and Early Pregnancy Scan',
      description: `This benefit allows you to claim a contribution towards 3D and 4D maternity scans or early pregnancy
      scan depending on your plan. See your table of cover for specific entitlements.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'Zika Screening',
      description: `Under this benefit you can claim a contribution towards the cost of a Zika screening consultation and/or test through
      the Tropical Medical Bureau.`,
      type: `Personalised package.`
    },
    {
      benefit: 'Pre/Postnatal Medical Expenses',
      description: `Under this benefit you can claim back some of the costs of pre/postnatal care provided by a consultant,
      GP or a midwife during and after your pregnancy. The following costs are included and can be claimed per pregnancy: <br />
      - Outpatient consultant’s fees (obstetrician and gynaecologist), Maternity scans, Antenatal classes run by a midwife – both
      pre and postnatal,<br />
      - Pre and postnatal physiotherapist services provided by UMamma or by a chartered physiotherapist with a speciality in women's health.`,
      type: `Outpatient/personalised package.`
    },
    {
      benefit: 'Public Hospital Fees',
      description: `Your insurer offers cover for either:<br />
      a) your hospital costs for up to three nights where you are admitted to a public hospital. The type of hospital accommodation that
      will be covered under this benefit is the same as that covered under your public hospitals cover in your in-patient benefits<br />
      OR<br />
      b) the contribution specified in your Table of Cover towards your hospital costs.`,
      type: `In-patient.`
    },
    {
      benefit: 'Caesarean Section',
      description: `Where your plan covers you for “up to three nights’ accommodation” but it is medically necessary for you to remain
      for more than three nights, the remainder of your stay in hospital will be covered under your in-patient benefits. Please note
      that caesarean section deliveries are covered under your in-patient benefits.`,
      type: `In-patient.`
    },
    {
      benefit: 'In-patient Maternity Consultant Fees',
      description: `Your insurer will pay a contribution towards your consultants professional fees. The amount and type of cover
      available under your plan is set out in your Table of Cover.`,
      type: `In-patient.`
    },
    {
      benefit: 'Partner Benefit',
      description: `With this benefit you can claim back some costs where you have to travel to be with your partner when they are
      admitted to a medical facility to give birth to your child. These include accommodation, travel and childminding.`,
      type: `Other maternity benefit/personalised package.`
    },
  ];
  explanations = null;
  getExplanations() {
    return this.data.map(e => ({
      question: e.benefit,
      answer: '<div class="maternity-explanation-type">Type: ' + e.type + '</div>' + e.description
    }));
  }

  constructor() { }

  ngOnInit() {
    this.explanations = this.getExplanations();
  }

}

import { Component, OnInit, Input } from '@angular/core';
import {User} from '../../model/user.model';
import {AgeType} from '../../model/age-type.model';
import {BeneficiaryModel} from '../../model/beneficiary.model';
import {InsurancePageComponent} from '../insurance-page.component';

@Component({
  selector: 'app-ins-page-general',
  templateUrl: './main-beneficiary-info.component.html',
  styleUrls: ['./main-beneficiary-info.component.scss']
})
export class MainBeneficiaryInfoComponent implements OnInit {
  @Input() parent: InsurancePageComponent;
  mainUser: User;
  yesNoOptions = ['Yes', 'No'];
  lcrUrl = '/benefits/lifetime-community-rating';

  constructor() { }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
    this.parent.mainUserAgeType = null;
  }

  changeName(event) {
    this.mainUser.name = event.target.value;
  }

  setInsurance(value) {
    this.mainUser.hasHealthInsuranceValue = value;
    this.mainUser.hasHealthInsurance = this.mainUser.hasHealthInsuranceValue === 'Yes';
    this.mainUser.hasHealthInsurance ? this.mainUser.mostAffordable = null : this.mainUser.mostAffordable = true;
  }

  getInsurance() {
    return this.mainUser.hasHealthInsuranceValue;
  }

  cannotAdvance() {
    return !this.mainUser.name ||
      this.mainUser.hasHealthInsurance == null ||
      !this.parent.totalNumberOfBeneficiaries() ||
      this.mainUser.beneficiaries.some(person => !this.parent.hasValue(person, 'dateOfBirth'));
  }

  coverNumberDecrease(type) {
    if (this.parent.getCount(type) !== 0) {
      for (let i = this.mainUser.beneficiaries.length; i--; i >= 0) { // deletes user data
        if (this.mainUser.beneficiaries[i].ageType === type) {
          this.mainUser.beneficiaries.splice(i, 1);
          break;
        }
      }
      if (this.parent.getCount(AgeType.ADULT) + this.parent.getCount(AgeType.YOUNGADULT) === 0) {
        this.mainUser.beneficiaries = [];
      } // this deletes all children if there's no adult or young adult
      if (this.parent.mainUserAgeType === type && this.parent.getCount(type) === 0 && this.parent.totalNumberOfBeneficiaries()) {
        this.parent.mainUserAgeType = this.mainUser.beneficiaries[0].ageType;
      }
    }
  }

  coverNumberIncrease(type) {
    const map = {};
    map[AgeType.ADULT] = 1;
    map[AgeType.YOUNGADULT] = 2;
    map[AgeType.CHILD] = 3;

    if (!this.parent.totalNumberOfBeneficiaries()) {
      if (type === AgeType.CHILD) {
        return;
      }
      this.parent.mainUserAgeType = type;
    }
    this.mainUser.beneficiaries = [...this.mainUser.beneficiaries, new BeneficiaryModel(type)];
    this.mainUser.beneficiaries.sort((a, b) =>
      (map[a.ageType] > map[b.ageType] ? 1 : ((map[b.ageType] > map[a.ageType]) ? -1 : 0)));
  }

  getMinDate(ageType) {
    return ageType === 'adult' ? this.parent.adultMinDate : ( ageType === 'youngAdult' ? this.parent.youngAdultMinDate : this.parent.childMinDate);
  }

  getMaxDate(ageType) {
    return ageType === 'adult' ? this.parent.adultMaxDate : ( ageType === 'youngAdult' ? this.parent.youngAdultMaxDate : this.parent.childMaxDate);
  }

  setBirthday(beneficiary: BeneficiaryModel, event) {
    if (event.target.value !== undefined) {
      event.target.value.setHours(20);
      beneficiary.dateOfBirth = event.target.value.toISOString();
    }
  }

  setIosBirthday(beneficiary: BeneficiaryModel, event) {
    if (event.target.value !== undefined) {
      let dateOfBirth = new Date(event.target.value);
      dateOfBirth.setHours(20);
      beneficiary.dateOfBirth = dateOfBirth.toISOString();
    }
  }

   iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || navigator.userAgent.toUpperCase().includes('MAC');
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../model/user.model';
import {InsurancePageComponent} from '../insurance-page.component';

@Component({
  selector: 'app-ins-page-planpackage',
  templateUrl: './plan-package.component.html',
  styleUrls: ['./plan-package.component.scss']
})
export class PlanPackageComponent implements OnInit {
  @Input() parent: InsurancePageComponent;
  mainUser: User;


  constructor() { }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AdminAccount} from '../../model/admin-account.model';

@Injectable({
  providedIn: 'root'
})

export class ManageAdminsService {
  constructor(private httpClient: HttpClient) { }

  sendInvite(data): Observable<any> {
    return this.httpClient.post<any>('/registration', data);
  }
  getAccounts(): Observable<AdminAccount[]> {
    return this.httpClient.get<AdminAccount[]>('/accounts?accountType=ADMIN');
  }
  banAccount(id: number) {
    return this.httpClient.post<any>('/disable?accountId=' + id, null);
  }
  enableAccount(id: number) {
    return this.httpClient.post<any>('/enable?accountId=' + id, null);
  }
}

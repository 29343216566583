import {Component, OnInit} from '@angular/core';
import {DataTransferService} from '../../core/services/data-transfer.service';
import {User} from '../../model/user.model';
import {AgeType} from '../../model/age-type.model';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users-options-page',
  templateUrl: './users-options-page.component.html',
  styleUrls: ['./users-options-page.component.scss']
})
export class UsersOptionsPageComponent implements OnInit {
  quoteCode: string;
  mainUser: User;
  mainUserAgeType: AgeType;
  userQuote: any;
  recommendedOptionDetails = [];
  beneficiaries = [];
  offersData = [];
  dataSource = [];
  selectedByUser = [];
  dataColumns = {Irish: [], Laya: [], VHI: []};
  rowLabels = [];
  selectedIndexes = [];
  detailsVisible = [false, false, false];
  constructor(private dataTransferService: DataTransferService,
              private router: Router) { }

  ngOnInit() {
    const urlParts = this.router.url.split('/');
    this.quoteCode = urlParts[urlParts.length - 1];
    this.dataTransferService.getSingleQuoteData(this.quoteCode).then(quote => {
      this.userQuote = quote;
      this.userQuote.insuranceRequests = quote.insuranceRequests.map(request => ({...request,
        beneficiaryType: request.beneficiaryType === 'CHILDREN' ? 'Child' : request.beneficiaryType.replace('_', ' ')
      }));

      this.getOffersDataAsObservable(this.userQuote.insuranceRequests).subscribe(allOffers => {
        allOffers.forEach(beneficiaryOffers => {
          let optionDetails = [];
          beneficiaryOffers.forEach(offer => {
            optionDetails = [...optionDetails, {
              price: offer.recommendedPlanDetails.price,
              excess: offer.recommendedPlanDetails.excess !== -1 ? offer.recommendedPlanDetails.excess : 0
            }];
          });
          this.recommendedOptionDetails = [...this.recommendedOptionDetails, optionDetails];
          const offersData = this.getDataArray(beneficiaryOffers);
          this.offersData = [...this.offersData, offersData];
          this.selectedIndexes = [...this.selectedIndexes, this.getRowIndexesToHighlight(beneficiaryOffers)];
        });
      });
      this.addBeneficiaryOrderNumber(quote);
    });
  }

  getOffersDataAsObservable(insRequests) {
    const observableBatch = [];
    insRequests.forEach(request => {
      observableBatch.push(this.dataTransferService.getOffersData(request.id));
    });
    return forkJoin(observableBatch);
  }

  addBeneficiaryOrderNumber(singleQuote) {
    let index = 1;
    for (let i = 1; i < singleQuote.insuranceRequests.length; i++) {
      const insReq = singleQuote.insuranceRequests[i];
      if (insReq.beneficiaryType === singleQuote.insuranceRequests[i - 1].beneficiaryType.replace(/ [0-9]/g, '')) {
        insReq.beneficiaryType = insReq.beneficiaryType + ' ' + (index + 1);
        index++;
      } else {
        index = 1;
        insReq.beneficiaryType = insReq.beneficiaryType + ' ' + index;
      }
    }
  }

  getDataArray(dataSource) {
    this.generateProvidersArrays(dataSource);
    this.rowLabels = ['Public Hospitals', 'Private Hospitals', 'Excess', 'Day Cases - Private Hospitals',
      'Day Cases - High Tech', 'Semi Private - High Tech', 'Private - High Tech'];
    this.dataSource = [];
    dataSource[0].benefitCategories.forEach(category => this.rowLabels = [...this.rowLabels, category.categoryDTO.name]);
    for (const rowLabel of this.rowLabels) {
      const i = this.rowLabels.indexOf(rowLabel);
      this.dataSource = [...this.dataSource,
        {Benefits: rowLabel, Irish: this.dataColumns.Irish[i], Laya: this.dataColumns.Laya[i], VHI: this.dataColumns.VHI[i]}];
    }
    return this.dataSource;
  }

  getRowIndexesToHighlight(data) {
    let selectedIndexes = [];
    this.selectedByUser = [];
    data[0].benefitCategories.forEach(category => {
      if (category.userSelectedCategory === true) {
        this.selectedByUser = [...this.selectedByUser, category.categoryDTO.name.toLowerCase()];
      }
    });
    this.rowLabels.forEach(row => {
      const i = this.rowLabels.indexOf(row);
      if (this.selectedByUser.includes(row.toLowerCase())) {
        selectedIndexes = [...selectedIndexes, i];
      }
    });
    return selectedIndexes;
  }

  generateProvidersArrays(dataSource) {
    this.dataColumns = {Irish: [], Laya: [], VHI: []};
    dataSource.forEach(plan => {
      switch (plan.recommendedPlanDetails.provider.toLowerCase()) {
        case 'irish_life_health':
          this.dataColumns.Irish = this.getProviderBenefits(plan, this.dataColumns.Irish);
          break;
        case 'laya':
          this.dataColumns.Laya = this.getProviderBenefits(plan, this.dataColumns.Laya);
          break;
        case 'vhi':
          this.dataColumns.VHI = this.getProviderBenefits(plan, this.dataColumns.VHI);
          break;
      }
    });
  }

  getProviderBenefits(plan, providerArray) {
    let benefitsValues = [];
    benefitsValues = [...providerArray,
      plan.hospitals[0].value, plan.hospitals[1].value, '€' + plan.recommendedPlanDetails.excess, plan.hospitals[1].dayCases,
      plan.hospitals[2].dayCases, plan.hospitals[2].value, plan.hospitals[3].value];
    plan.benefitCategories.forEach(category => benefitsValues = [...benefitsValues, category.benefitDTO.value]);
    return benefitsValues;
  }
}

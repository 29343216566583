import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserQuoteModel} from '../../../../model/user-quote.model';
import {DataTransferService} from '../../../../core/services/data-transfer.service';
import {EmployerQuoteModel} from '../../../../model/employer-quote.model';
import {NotifService} from '../../../../core/services/notification.service';

@Component({
  selector: 'app-purchase-quote',
  templateUrl: './purchase-quote.component.html',
  styleUrls: ['./purchase-quote.component.scss']
})
export class PurchaseQuoteComponent implements OnInit {
  @Input() showPurchaseQuoteDialog: boolean;
  @Input() userQuote: UserQuoteModel;
  @Input() employerQuote: EmployerQuoteModel;
  @Input() showNtuDialog: boolean;
  @Output() hideQuoteDialog = new EventEmitter();
  purchaseQuoteForm: FormGroup;
  plansOrOptions = [];
  finalizeRequests = [];
  requestBody: any;
  purchaseText: string;
  submitButtonEnabled: boolean;

  constructor(private fb: FormBuilder,
              private dataTransferService: DataTransferService,
              private notificationService: NotifService) { }

  ngOnInit() {
    this.purchaseQuoteForm = this.fb.group({
      userName: ['']
    });
    if (this.userQuote) {
      for (const insRequest of this.userQuote.insuranceRequests) {
        if (insRequest.proposedPlanName || insRequest.selectedOptionPlanName) {
          this.plansOrOptions = [...this.plansOrOptions, {id: insRequest.id, selected: false, proposed: false}];
        } else {
          this.plansOrOptions = [...this.plansOrOptions, null];
        }
      }
      this.purchaseText = 'Select purchase quote.';
    } else if (this.employerQuote) {
      this.purchaseText = 'Purchase quote.';
    }
  }

  hideDialog(event) {
    this.hideQuoteDialog.emit(event);
  }

  finalizeQuote(event) {
    if (this.userQuote) {
      if (this.showNtuDialog) {
        this.requestBody = this.getFinalizeData('NTU');
      } else if (this.showPurchaseQuoteDialog) {
        this.requestBody = this.getFinalizeData('PURCHASE');
      }
      this.dataTransferService.finalizeUserQuote(this.requestBody).subscribe(
        () => location.reload(),
        error => this.notificationService.error(error.message)
      );
      this.finalizeRequests = [];
    } else if (this.employerQuote) {
      const operation = this.showPurchaseQuoteDialog ? 'PURCHASE' : 'NTU';
      this.dataTransferService.finalizeEmployerQuote(this.employerQuote[0].id, operation).subscribe(
        () => location.reload(),
        error => this.notificationService.error(error.message)
      );
    }
    this.hideDialog(event);
  }

  getFinalizeData(operationType) {
    for (const finalizeRequest of this.plansOrOptions) {
      if (finalizeRequest !== null) {
        this.finalizeRequests = [...this.finalizeRequests, {
          insuranceRequestId: finalizeRequest.id,
          option: finalizeRequest.selected ? 'SELECTED' : (finalizeRequest.proposed ? 'PROPOSED' : null)
        }];
      }
    }
    return {
      insuranceId: this.userQuote.id,
      operation: operationType,
      finalizeRequests: this.finalizeRequests
    };
  }

  removeSelection(index, option) {
    if (option === 'proposed') {
      this.plansOrOptions[index].proposed = null;
    }
    if (option === 'selected') {
      this.plansOrOptions[index].selected = null;
    }
    this.checkIfSelected();
    return false;
  }

  checkIfSelected() {
    if (this.userQuote) {
      let noOfSelectedPlans = 0;
      this.plansOrOptions.forEach(planOrOption => {
        if (planOrOption && (planOrOption.proposed === true || planOrOption.selected === true)) {
          this.submitButtonEnabled = true;
          noOfSelectedPlans++;
        }
      });
      if (noOfSelectedPlans === 0) {
        this.submitButtonEnabled = false;
      }
    }
  }
}

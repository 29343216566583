import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UserService } from "../../../core/services/user.service";
import { Router } from "@angular/router";
import { ChangePasswordRequest } from "../../../model/change-password-request.model";
import { AuthenticationService } from "../../../core/services/auth.service";
import { ChangeNameAndEmailRequest } from "src/app/model/change-name-and-email.model";
import { HeaderComponent } from "../../../shared/header/header.component";
import { FileService } from "../../../core/services/file-transfer.service";
import { formatDate } from "@angular/common";
import { FilesResponse } from "../../../model/files-response.model";
import { saveAs } from "file-saver";
import { NotifService } from "../../../core/services/notification.service";

@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.scss"],
})
export class SettingsPageComponent implements OnInit {
  changePassForm: FormGroup;
  changeNameAndEmailForm: FormGroup;
  updateExcelForm: FormGroup;
  changePassError: string;
  changePassSuccess: string;
  passPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,50}$";
  passFormSubmitted = false;
  nameFormSubmitted = false;
  currentName: string;
  currentEmail: string;
  nameAndEmailSuccessMessage = null;
  nameAndEmailErrorMessage = null;
  hidePassword = [true, true, true];
  lastFutureDocName: string;
  lastMasterDocName: string;
  nextApplianceDay = "";
  lastFutureDocUploadDate = null;
  lastMasterDocUploadDate = null;
  changeExcelError: string;
  changeExcelSuccess: string;
  changeFutureExcelSuccess: string;
  changeFutureExcelError: string;
  currentFiles: FilesResponse[] = [];
  @ViewChild(HeaderComponent, { static: true })
  headerComponent: HeaderComponent;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private fileService: FileService,
    private router: Router,
    private notificationService: NotifService
  ) {}

  ngOnInit() {
    this.changePassForm = new FormGroup(
      {
        oldPassword: new FormControl("", [Validators.required]),
        newPassword: new FormControl("", [
          Validators.required,
          Validators.pattern(this.passPattern),
        ]),
        confirmNewPassword: new FormControl("", [
          Validators.required,
          Validators.pattern(this.passPattern),
        ]),
      },
      {
        validators: this.validatePassword.bind(this),
      }
    );

    this.changeNameAndEmailForm = new FormGroup({
      newName: new FormControl("", [Validators.required]),
      newEmail: new FormControl("", [Validators.required, Validators.email]),
    });

    this.updateExcelForm = new FormGroup({}); // TODO implement excel update
    this.getCurrentNameAndEmail();
    this.getCurrentFilesDetails();
  }

  getCurrentFilesDetails() {
    this.fileService.getFiles().subscribe(
      (success) => {
        for (const file of success) {
          if (file.versionType === "CURRENT") {
            this.lastMasterDocName = file.fileName;
            this.lastMasterDocUploadDate = formatDate(
              file.createdOn,
              "dd/MM/yyyy",
              "en-us"
            );
          }
          if (file.versionType === "FUTURE") {
            this.lastFutureDocName = file.fileName;
            this.lastFutureDocUploadDate = formatDate(
              file.createdOn,
              "dd/MM/yyyy",
              "en-us"
            );
          }
        }
      },
      (error) => this.notificationService.error(error.error)
    );
  }

  getCurrentNameAndEmail() {
    if (localStorage.getItem("currentUserName") !== null) {
      this.currentName = localStorage.getItem("currentUserName");
    } else if (sessionStorage.getItem("currentUserName") !== null) {
      this.currentName = sessionStorage.getItem("currentUserName");
    } else {
      this.currentName = "Your name";
    }
    if (localStorage.getItem("currentUserEmail") !== null) {
      this.currentEmail = localStorage.getItem("currentUserEmail");
    } else if (sessionStorage.getItem("currentUserEmail") !== null) {
      this.currentEmail = sessionStorage.getItem("currentUserEmail");
    } else {
      this.currentEmail = "Your email";
    }
    this.headerComponent.getUserName();
  }

  get changePassFormControls() {
    return this.changePassForm.controls;
  }
  get nameAndEmailControls() {
    return this.changeNameAndEmailForm.controls;
  }

  validatePassword(passwordsFormGroup: AbstractControl) {
    const password = passwordsFormGroup.get("newPassword").value;
    const confirmPassword = passwordsFormGroup.get("confirmNewPassword").value;
    return password !== confirmPassword ? { mismatchedPasswords: true } : null;
  }

  nameAndEmailSubmit() {
    this.nameFormSubmitted = true;
    if (this.changeNameAndEmailForm.valid) {
      const newNameValue = this.changeNameAndEmailForm.get("newName").value;
      const newEmailValue = this.changeNameAndEmailForm.get("newEmail").value;
      const changeRequest = new ChangeNameAndEmailRequest(
        newNameValue,
        newEmailValue
      );
      this.userService.changeNameAndEmail(changeRequest).subscribe(
        () => {
          this.nameAndEmailSuccessMessage =
            " Name and email changed successfully.";
          setTimeout(() => {
            this.nameAndEmailSuccessMessage = null;
          }, 3000);
          this.authService.updateStorage(
            this.changeNameAndEmailForm.get("newName").value,
            this.changeNameAndEmailForm.get("newEmail").value
          );
          this.getCurrentNameAndEmail();
          this.changeNameAndEmailForm.reset();
          this.nameFormSubmitted = false;
        },
        (error) => {
          this.nameAndEmailErrorMessage = error.error;
          setTimeout(() => {
            this.nameAndEmailErrorMessage = null;
          }, 3000);
          this.nameFormSubmitted = false;
        }
      );
    }
  }

  togglePasswordVisibility(index) {
    this.hidePassword[index] = !this.hidePassword[index];
  }

  uploadFile(event) {
    let docType = "master";
    if (this.nextApplianceDay === "") {
      this.nextApplianceDay = "";
    } else {
      this.nextApplianceDay = formatDate(
        this.nextApplianceDay,
        "yyyy-MM-dd",
        "en"
      );
      docType = "future";
    }

    const data = {
      fileToUpload: event.target.files.item(0),
      nextVersionApplianceDate: this.nextApplianceDay,
    };
    this.fileService.fileUpload(data).subscribe(
      () => {
        docType === "master"
          ? (this.changeExcelSuccess = "File successfully uploaded.")
          : (this.changeFutureExcelSuccess = "File successfully uploaded");
        setTimeout(() => {
          docType === "master"
            ? (this.changeExcelSuccess = null)
            : (this.changeFutureExcelSuccess = null);
        }, 5000);
        this.getCurrentFilesDetails();
      },
      (error) => {
        docType === "master"
          ? (this.changeExcelError = error.error)
          : (this.changeFutureExcelError = error.error);
        setTimeout(() => {
          docType === "master"
            ? (this.changeExcelError = null)
            : (this.changeFutureExcelError = null);
        }, 5000);
      }
    );
  }

  downloadFile(futureVersion: boolean) {
    let data = {};
    if (futureVersion) {
      data = {
        filename: this.lastFutureDocName,
        isFutureVersion: futureVersion,
      };
    } else {
      data = {
        filename: this.lastMasterDocName,
        isFutureVersion: futureVersion,
      };
    }
    this.fileService.fileDownload(data).subscribe(
      (response) =>
        this.saveFileToDesktop(
          response,
          "application/vnd.ms-excel",
          futureVersion
        ),
      (error) => {
        futureVersion
          ? (this.changeFutureExcelError = error.error)
          : (this.changeExcelError = error.error);
        setTimeout(() => {
          futureVersion
            ? (this.changeFutureExcelError = null)
            : (this.changeExcelError = null);
        }, 5000);
      }
    );
  }

  saveFileToDesktop(data: any, type: string, isFutureVersion: boolean) {
    const blob = new Blob([data], { type: type.toString() });
    const filename = isFutureVersion
      ? this.lastFutureDocName
      : this.lastMasterDocName;
    saveAs(blob, filename);
  }

  setNextApplianceDate(event) {
    this.nextApplianceDay = event.target.value;
  }

  checkDate() {
    return this.nextApplianceDay === "";
  }

  clearError() {
    if (this.changePassError !== null) {
      this.changePassError = null;
    }
  }

  changePassSubmit() {
    this.passFormSubmitted = true;
    if (this.changePassForm.valid) {
      const oldPasswordValue = this.changePassForm.get("oldPassword").value;
      const newPasswordValue = this.changePassForm.get("newPassword").value;
      const confirmNewPasswordValue =
        this.changePassForm.get("confirmNewPassword").value;
      const changeRequest = new ChangePasswordRequest(
        confirmNewPasswordValue,
        newPasswordValue,
        oldPasswordValue
      );
      this.userService.changePassword(changeRequest).subscribe(
        (success) => {
          this.changePassSuccess = "Password changed successfully.";
          setTimeout(() => {
            this.changePassSuccess = null;
          }, 3000);
          this.changePassForm.reset();
          this.passFormSubmitted = false;
        },
        (error) => {
          this.changePassError = error.error;
          this.passFormSubmitted = false;
        }
      );
    }
  }
}

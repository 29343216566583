import {Component, Input, OnInit} from '@angular/core';
import {InsuranceRequestModel} from '../../../../model/insurance-request.model';
import {DataTransferService} from '../../../../core/services/data-transfer.service';
import {Router} from '@angular/router';
import {AdminOffersService} from '../../../../core/services/admin-offers.service';
import {UserQuoteModel} from '../../../../model/user-quote.model';

@Component({
  selector: 'app-user-quote-card',
  templateUrl: './user-quote-card.component.html',
  styleUrls: ['./user-quote-card.component.scss']
})
export class UserQuoteCardComponent implements OnInit {

  @Input() name: string;
  @Input() dateOfBirth: string;
  @Input() email: string;
  @Input() insuranceRequest: InsuranceRequestModel;
  @Input() quoteCode: string;
  @Input() userQuote: UserQuoteModel;
  isPlanProposed = false;
  showProposePlanDialog = false;
  selectedOptionName: string;

  constructor(private dataTransferService: DataTransferService,
              private router: Router,
              private adminOffersService: AdminOffersService) { }

  ngOnInit() {
    if (this.insuranceRequest.selectedOptionPlanName) {
      this.selectedOptionName = this.insuranceRequest.selectedOptionPlanName;
    } else {
      this.selectedOptionName = 'VIEW OFFERS';
    }
    if (this.insuranceRequest.proposedPlanName) {
      this.isPlanProposed = true;
    }
  }

  hideProposePlanDialog() {
    this.showProposePlanDialog = false;
  }

  showProposedPlanName(proposedPlanValues) {
    this.insuranceRequest.proposedPlanProvider = proposedPlanValues.providerType.replace(/[_]/g, ' ');
    this.insuranceRequest.proposedPlanName = proposedPlanValues.planName;
    this.isPlanProposed = true;
  }

  openOffersPage() {
    this.adminOffersService.setCodeQuote(this.quoteCode);
    this.adminOffersService.isEmailSet = this.email !== null;
    this.adminOffersService.setInsuranceRequest(this.insuranceRequest);
    this.adminOffersService.setStatus(this.userQuote.status);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-find-more-card',
  templateUrl: './find-more-card.component.html',
  styleUrls: ['./find-more-card.component.scss']
})
export class FindMoreCardComponent implements OnInit {
  @Input() cardData = {
    iconName: '',
    cardTitle: '',
    cardDescription: '',
    navLink: ''
  };
  constructor(private router: Router) { }

  ngOnInit() {
  }

}

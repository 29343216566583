import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ChangePasswordRequest } from '../../../model/change-password-request.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  changePassForm: FormGroup;
  changePassError: string;
  changePassSuccess: string;
  passPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,50}$';
  formSubmitted = false;

  constructor(private userService: UserService,
              private router: Router) { }

  ngOnInit() {
    this.changePassForm = new FormGroup({
      oldPassword : new FormControl('', [Validators.required, Validators.pattern(this.passPattern)]),
      newPassword : new FormControl('', [Validators.required,  Validators.pattern(this.passPattern)]),
      confirmNewPassword: new FormControl('', [Validators.required, Validators.pattern(this.passPattern)])
    }, {
      validators: this.validatePassword.bind(this)
    });
  }

  get changeFormControls() { return this.changePassForm.controls; }

  validatePassword(passwordsFormGroup: AbstractControl) {
    const password = passwordsFormGroup.get('newPassword').value;
    const confirmPassword = passwordsFormGroup.get('confirmNewPassword').value;
    return (password !== confirmPassword) ? {mismatchedPasswords: true} : null;
  }

  changePassSubmit() {
    this.formSubmitted = true;
    if (this.changePassForm.valid) {
      const oldPasswordValue = this.changePassForm.get('oldPassword').value;
      const newPasswordValue = this.changePassForm.get('newPassword').value;
      const confirmNewPasswordValue = this.changePassForm.get('confirmNewPassword').value;
      const changeRequest = new ChangePasswordRequest(confirmNewPasswordValue, newPasswordValue, oldPasswordValue);
      this.userService.changePassword(changeRequest).subscribe(
        success => {
          this.changePassSuccess = 'Password changed successfully.';
          setTimeout(() => {
            this.router.navigate(['/admin']);
          }, 2000);
        },
        error => {
          this.changePassError = error.error;
          setTimeout(() => { this.changePassError = null; }, 2000);
        }
      );
    }
  }
}

export class RecommendedOptionDetails {
    providerName: string;
    planName: string;
    excess: number;
    price: number;
  
    constructor(providerName, planName, excess, price) {
      this.providerName = providerName;
      this.planName = planName;
      this.excess = excess;
      this.price = price;
    }
  }
  
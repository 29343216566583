import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-more',
  templateUrl: './find-more.component.html',
  styleUrls: ['./find-more.component.scss']
})
export class FindMoreComponent implements OnInit {
  cardsData = [
    {
      iconName: 'home_newtohealth.svg',
      cardTitle: 'New to Health Insurance?',
      cardDescription: 'We will compare the market and find the plan that best suits your needs.',
      navLink: 'benefits/new-to-health'
    },
    {
      iconName: 'home_employer.svg',
      cardTitle: 'Are You an employer ?',
      cardDescription: 'You can trust our team to compare all plans on the market and find the best plan to suit your employees, at the lowest ' +
        'price, while you focus on your business.',
      navLink: 'benefits/employer-information'
    },
    {
      iconName: 'home_LCR.svg',
      cardTitle: 'Lifetime Community Rating',
      cardDescription: 'Do you know why it is so important to purchase health insurance before 35 ?',
      navLink: 'benefits/lifetime-community-rating'
    },
    {
      iconName: 'home_maternity.svg',
      cardTitle: 'Family Planning',
      cardDescription: 'Whether you are trying to conceive, or are expecting a baby, we can answer all your questions on fertility and maternity',
      navLink: 'benefits/maternity-benefits'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}

import {Injectable} from '@angular/core';
import {InsuranceRequestModel} from '../../model/insurance-request.model';

@Injectable({providedIn: 'root'})
export class AdminOffersService {
  codeQuote: string;
  isEmailSet: boolean;
  insuranceRequest: InsuranceRequestModel;
  quoteStatus: string;

  setCodeQuote(codeQuote) {
    this.codeQuote = codeQuote;
  }

  setInsuranceRequest(request) {
    this.insuranceRequest = request;
  }

  setStatus(status) {
    this.quoteStatus = status;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit {
  tips = [
    {
      title: 'Review your cover every year.',
      description: 'Always be sure to speak with your broker to ensure you are on the best plan to suit your needs.'
    },
    {
      title: 'Split your cover.',
      description: `Everyone on the same policy does not have to opt for the same plan. For example, you may be interested in Maternity/Fertility
      cover while your partner may be more interested in Sports cover. It is important to ensure each member is covered on a plan that suits
      their needs and everyone is not placed on the same plan without thought.`
    },
    {
      title: 'Avail of any special offers for children.',
      description: `Insurers often have special offers such as offering half price for children or plans where you will only pay for your one
      child and any subsequent children are free. We can help you find these plans.`
    },
    {
      title: 'Ask for the newest ‘Corporate Plan’.',
      description: `These are plans that are often released for the large corporate clients and they offer the best value. Legally, all plans
       have to be made available to everyone but the insurers are not obliged to tell people about these plans. These plans often have funny
       names and are harder to remember. We can help you find the corporate equivalent to your existing plan.`
    },
    {
      title: 'Take on an Excess.',
      description: `Applying an excess to your plan can reduce your costs. Excesses only apply to private hospitals and the amount is typically
       per stay, not per night. The higher the excess, the lower your annual premium will be.`
    },
    {
      title: 'Consider your everyday costs.',
      description: `If you attend practitioners like GPs, Physiotherapists and Dentists often throughout the year, you should opt for a plan
       where you claim money back on these visits. Corporate plans often offer the best cover on these day-to-day costs.`
    },
    {
      title: 'Don’t be afraid to switch providers.',
      description: `You do not have to reserve waiting periods, all insurers must honour the waiting periods you have served with your previous
       provider.`
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}

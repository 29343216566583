import {Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {InsurancePageComponent} from '../insurance-page.component';
import {User} from '../../model/user.model';
import {BeneficiaryModel} from '../../model/beneficiary.model';
import {EmailFormComponent} from '../../shared/email-form/email-form.component';
import {DataTransferService} from '../../core/services/data-transfer.service';
import { RecommendedOptionDetails } from 'src/app/model/recommendedOptionDetails.model';
import { BenefitCategories } from 'src/app/model/benefit-categories.model';
import { HospitalDetails } from 'src/app/model/hospital-details.model';
import {NotifService} from '../../core/services/notification.service';

@Component({
  selector: 'app-ins-page-last',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.scss']
})
export class LastStepComponent implements OnInit {
  @Input() parent: InsurancePageComponent;
  @Input() recommendedOptionDetails: RecommendedOptionDetails[];
  @Input() quoteCode: string;
  @Input() splitQuoteCode: string;
  @Input() minPrice: number;
  @Input() beneficiaryBenefitsPerCategories: BenefitCategories[][];
  @Input() hospitalDetailsPerBeneficiary: HospitalDetails[][];
  @Output() emailFormSubmitted = new EventEmitter();
  @ViewChild(EmailFormComponent, {static: false}) emailFormComponent: EmailFormComponent;
  mainUser: User;
  formSubmitted: boolean;
  hospitalNameMap: any = {
    PUBLIC: 'Public Hospital',
    PRIVATE: 'Private Hospital',
    HIGH_TECH_PRIVATE: 'High-Tech Private Hospital',
    HIGH_TECH_SEMI_PRIVATE: 'High-Tech Semi-Private Hospital',
  };

  constructor(private dataTransferService: DataTransferService,
              private changeDetectorRef: ChangeDetectorRef,
              private notificationService: NotifService) { }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
  }

  toggleExpand(beneficiary: BeneficiaryModel) {
    beneficiary.finalOptionsDetails = !beneficiary.finalOptionsDetails;
  }

  submitEmailForm(emailData) {
    const requestData = {
      email: emailData.email,
      phoneNumber: emailData.phoneNumber,
      quoteCode: this.quoteCode
    };
    this.dataTransferService.emailFormSubmit(requestData).subscribe(
      () => {
        this.formSubmitted = true;
        this.emailFormComponent.emailForm.reset();
        this.notificationService.success('Thank you, we will get in touch with you as soon as possible.');
        this.emailFormSubmitted.emit(true);
        },
      error => {
        this.formSubmitted = false;
        this.emailFormComponent.emailForm.reset();
        this.notificationService.error(error.message);
      }
    );
  }
}

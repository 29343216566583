import {CreatedOnModel} from '../../model/created-time.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDateService {
  dateToString: Date;

  changeDateFormat(dateObject: CreatedOnModel | number) {
    if (typeof dateObject !== 'number') {
      const newDate = new Date();
      newDate.setFullYear(dateObject[0]);
      newDate.setMonth(dateObject[1]);
      newDate.setDate(dateObject[2]);
      this.dateToString = new Date(newDate.toDateString());
    } else {
      this.dateToString = new Date(dateObject);
    }
    const dd = this.dateToString.getDate();
    const mm = typeof dateObject !== 'number' ? this.dateToString.getMonth() :  this.dateToString.getMonth() + 1;
    const yyyy = this.dateToString.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }
}

import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'health-care-compare';
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        gtag('set', 'page', evt.urlAfterRedirects);
        gtag('send', 'pageview');
        window.scrollTo(0, 0);
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifService} from '../../../core/services/notification.service';
import {DataTransferService} from '../../../core/services/data-transfer.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cost-manager',
  templateUrl: './cost-manager.component.html',
  styleUrls: ['./cost-manager.component.scss']
})
export class CostManagerComponent implements OnInit {
  sendCostsForm: FormGroup;
  values = null;
  fieldNameMap = {
    budget: 'Annual Budget',
    excess: 'Level of Excess',
  };
  packages = [];
  budgetsAndExcess = {
    basic: {budgets: {first: null, second: null, third: null}},
    bronze: {budgets: {first: null, second: null, third: null}, excesses: {first: null, second: null, third: null}},
    silver: {budgets: {first: null, second: null, third: null}, excesses: {first: null, second: null, third: null}},
    gold: {budgets: {first: null, second: null, third: null}, excesses: {first: null, second: null, third: null}},
    platinum: {budgets: {first: null, second: null, third: null}, excesses: {first: null, second: null, third: null}}
  };

  constructor(private dataTransferService: DataTransferService,
              private notificationService: NotifService,
              private router: Router) { }

  ngOnInit() {
    this.getPlanPackages();
    this.initForm();
  }

  get formControls() { return this.sendCostsForm.controls; }

  initForm() {
    this.sendCostsForm = new FormGroup({
      basic: new FormGroup({
        budgets: this.getABudgetForm(),
      }),
      bronze: new FormGroup({
        budgets: this.getABudgetForm(),
        excesses: this.getAnExcessForm(),
      }),
      silver: new FormGroup({
        budgets: this.getABudgetForm(),
        excesses: this.getAnExcessForm(),
      }),
      gold: new FormGroup({
        budgets: this.getABudgetForm(),
        excesses: this.getAnExcessForm(),
      }),
      platinum: new FormGroup({
        budgets: this.getABudgetForm(),
        excesses: this.getAnExcessForm(),
      })
    });
  }

  getABudgetForm() {
    return new FormGroup({
      first: new FormControl(''),
      second: new FormControl(''),
      third: new FormControl(''),
    });
  }

  getAnExcessForm() {
    return new FormGroup({
      first: new FormControl('', [Validators.min(0)]),
      second: new FormControl('', [Validators.min(0)]),
      third: new FormControl('', [Validators.min(0)]),
    });
  }

  getPlanPackages() {
    this.dataTransferService.getPlanPackages().subscribe(success => {
      success.forEach((packageType, index) => {
        this.packages = [...this.packages, {type: '', id: null, budgets: [{annualBudget: null, id: null}], excesses: [{ excess: null, id: null}] }];
        this.packages[index].type = packageType.type;
        packageType.budgets.map((budget, innerIndex) => this.packages[index].budgets[innerIndex] = budget);
        packageType.excesses.map((excess, innerIndex) => this.packages[index].excesses[innerIndex] = excess);
        this.packages[index].id = packageType.id;
      });

      this.budgetsAndExcess = {
        basic: {
          budgets: {first: this.packages[0].budgets[0].annualBudget,
                   second: this.packages[0].budgets[1].annualBudget,
                   third: this.packages[0].budgets[2].annualBudget}},
        bronze: {
          budgets: {first: this.packages[1].budgets[0].annualBudget,
                   second: this.packages[1].budgets[1].annualBudget,
                   third: this.packages[1].budgets[2].annualBudget},
          excesses: {first: this.packages[1].excesses[0].excess,
                     second: this.packages[1].excesses[1].excess,
                     third: this.packages[1].excesses[2].excess}
        },
        silver: {
          budgets: {first: this.packages[2].budgets[0].annualBudget,
                   second: this.packages[2].budgets[1].annualBudget,
                   third: this.packages[2].budgets[2].annualBudget},
          excesses: {first: this.packages[2].excesses[0].excess,
                     second: this.packages[2].excesses[1].excess,
                     third: this.packages[2].excesses[2].excess}
        },
        gold: {
          budgets: {first: this.packages[3].budgets[0].annualBudget,
                   second: this.packages[3].budgets[1].annualBudget,
                   third: this.packages[3].budgets[2].annualBudget},
          excesses: {first: this.packages[3].excesses[0].excess,
                     second: this.packages[3].excesses[1].excess,
                     third: this.packages[3].excesses[2].excess}
        },
        platinum: {
          budgets: {first: this.packages[4].budgets[0].annualBudget,
                   second: this.packages[4].budgets[1].annualBudget,
                   third: this.packages[4].budgets[2].annualBudget},
          excesses: {first: this.packages[4].excesses[0].excess,
                     second: this.packages[4].excesses[1].excess,
                     third: this.packages[4].excesses[2].excess}
        }
      };
    });
  }

  getCells() {
    return ['first', 'second', 'third'];
  }

  collectData() {
    const costsFormDataArr = Object.entries(this.sendCostsForm.getRawValue());
    costsFormDataArr.forEach(packageArr => {
      Object.entries(packageArr[1]).forEach(valuesObj => Object.values(valuesObj[1]).forEach( (value, index) => {
        if (value !== '' && value >= 0 && value !== null) {
          this.packages.forEach(singlePackage => {
            if (singlePackage.type.toLowerCase() === packageArr[0].toLowerCase()) {
              valuesObj[0] === 'budgets' ?
                singlePackage[`${valuesObj[0]}`][index].annualBudget = value : singlePackage[`${valuesObj[0]}`][index].excess = value;
            }
          });
        }
      }));
    });
  }

  sendCosts() {
    this.collectData();
    this.dataTransferService.updateBudgetsAndExcesses(this.packages).subscribe(
      () => this.notificationService.success('New values saved successfully'),
      error => this.notificationService.error(error.message)
    );
  }
}

import {Component, OnInit, Input} from '@angular/core';
import {InsurancePageComponent} from '../insurance-page.component';
import {User} from '../../model/user.model';
import {DataTransferService} from '../../core/services/data-transfer.service';
import {BeneficiaryModel} from '../../model/beneficiary.model';
import {NotifService} from '../../core/services/notification.service';

@Component({
  selector: 'app-current-insurance-details',
  templateUrl: './current-insurance-details.component.html',
  styleUrls: ['../insurance-page.component.scss', './current-insurance-details.component.scss']
})
export class CurrentInsuranceDetailsComponent implements OnInit  {
  @Input() parent: InsurancePageComponent;
  mainUser: User;
  errorMessage: string;

  constructor(private dataTransferService: DataTransferService,
              private notifService: NotifService) {
  }

  ngOnInit() {
    this.mainUser = this.parent.mainUser;
  }

  getSelectedProviderPlans(beneficiary: BeneficiaryModel, event) {
    const provider = event.value.replace(/[ ]/g, '_');
    this.dataTransferService.getProviderPlans(provider).subscribe(success => {
      beneficiary.currentHealthPlan.planOptions = success;
    }, error => {
      this.notifService.error(error.message);
    });
  }
}

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {LoginResponse} from '../../model/login-response.model';
import {Router} from '@angular/router';
import {HttpClient } from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  loginResponse: LoginResponse;
  rememberMe = true;
  private loginBehaviorSubject = new Subject<LoginResponse>();
  private logoutBehaviorSubject = new Subject<LoginResponse>();

  constructor(private router: Router,
              private httpClient: HttpClient) {
  }

  login(email: string, password: string): Observable<any> {
    const body = { email, password};
    return this.httpClient.post<LoginResponse>('/login', body).pipe(
      tap(response => {
        this.loginResponse = response;
        this.loginBehaviorSubject.next();
        if (this.rememberMe) {
          localStorage.setItem('currentUserToken', response.token);
          localStorage.setItem('currentUserName', response.principal.fullName);
          localStorage.setItem('currentUserEmail', response.principal.email);
          localStorage.setItem('currentUserType', response.principal.accountType);
        } else {
          sessionStorage.setItem('currentUserToken', response.token);
          sessionStorage.setItem('currentUserName', response.principal.fullName);
          sessionStorage.setItem('currentUserEmail', response.principal.email);
          sessionStorage.setItem('currentUserType', response.principal.accountType);
        }
      })
    );
  }

  updateStorage(newName, newEmail) {
    if (localStorage.getItem('currentUserName') === null || localStorage.getItem('currentUserEmail') === null) {
      sessionStorage.setItem('currentUserName', newName);
      sessionStorage.setItem('currentUserEmail', newEmail);
    } else {
      localStorage.setItem('currentUserName', newName);
      localStorage.setItem('currentUserEmail', newEmail);
    }
  }

  logout() {
    this.loginResponse = null;
    this.logoutBehaviorSubject.next();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  getToken(): string {
    if (this.loginResponse) {
      return this.loginResponse.token;
    } else if (localStorage.getItem('currentUserToken')) {
      return localStorage.getItem('currentUserToken');
    } else {
      return null;
    }
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }
}

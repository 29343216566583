import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {LogoComponent} from './logo/logo.component';
import {EmailFormComponent} from './email-form/email-form.component';
import {HideButtonComponent} from './hide-button/hide-button.component';
import {HeadermenuitemComponent} from './header/headermenuitem/headermenuitem.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {AccordionComponent} from './accordion/accordion.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { RemunerationAndFeesComponent } from './footer/remuneration-and-fees/remuneration-and-fees.component';
import { PrivacyNoticeComponent } from './footer/privacy-notice/privacy-notice.component';
import { PrivacyStatementComponent } from './footer/privacy-statement/privacy-statement.component';
import { TermsOfBusinessComponent } from './footer/terms-of-business/terms-of-business.component';
import {NotificationComponent} from './notification/notification.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeadermenuitemComponent,
    FooterComponent,
    LogoComponent,
    EmailFormComponent,
    HideButtonComponent,
    AccordionComponent,
    UnderConstructionComponent,
    RemunerationAndFeesComponent,
    PrivacyNoticeComponent,
    PrivacyStatementComponent,
    TermsOfBusinessComponent,
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    HeaderComponent,
    HeadermenuitemComponent,
    FooterComponent,
    LogoComponent,
    EmailFormComponent,
    HideButtonComponent,
    AccordionComponent,
    RemunerationAndFeesComponent,
    PrivacyNoticeComponent,
    PrivacyStatementComponent,
    TermsOfBusinessComponent,
    NotificationComponent
  ],
})

export class SharedModule {

}

import {Component, Input, OnInit} from '@angular/core';
import {DataTransferService} from '../../core/services/data-transfer.service';
import {Router} from '@angular/router';
import {User} from '../../model/user.model';
import {AgeType} from '../../model/age-type.model';
import {RecommendedPlanDetails} from '../../model/recommendedPlanDetails.model';

@Component({
  selector: 'app-plan-options',
  templateUrl: './plan-options.component.html',
  styleUrls: ['./plan-options.component.scss']
})
export class PlanOptionsComponent implements OnInit {
  quoteCode: string;
  mainUser: User;
  mainUserAgeType: AgeType;
  recommendedPlanDetails: RecommendedPlanDetails;
  beneficiaries = [];
  offersData = [];
  dataSource = [];
  selectedByUser = [];
  dataColumn = [];
  rowLabels = [];
  selectedIndexes = [];
  detailsVisible = false;
  isUserNameAvailable = false;
  @Input() isPlanProposed: boolean;
  @Input() isUserPlanPurchased: boolean;
  @Input() isEmployerPlanPurchased: boolean;

  constructor(private dataTransferService: DataTransferService,
              private router: Router) { }

  ngOnInit() {
    const currentUrl = this.router.url.split('/');
    const reqUrl = this.isPlanProposed || this.isUserPlanPurchased ? '/insurance/details?hash=' : '/insurance/details/employer?hash=';
    this.dataTransferService.getPlanDetails(currentUrl[currentUrl.length - 1], reqUrl).subscribe(
      success => {
        this.recommendedPlanDetails = success.recommendedPlanDetails;
        this.offersData = this.getDataArray(success);
        this.selectedIndexes = this.getRowIndexesToHighlight(success);
      }
    );
  }

  getDataArray(data) {
    this.dataColumn = this.getProviderBenefits(data);
    this.rowLabels = ['Public Hospitals', 'Private Hospitals', 'Excess', 'Day Cases - Private Hospitals',
      'Day Cases - High Tech', 'Semi Private - High Tech', 'Private - High Tech'];
    this.dataSource = [];
    data.benefitCategories.forEach(category => this.rowLabels = [...this.rowLabels, category.categoryDTO.name]);
    let i = 0;
    for (const rowLabel of this.rowLabels) {
      this.dataSource = [...this.dataSource,
        {Benefits: rowLabel, benefitsValues: this.dataColumn[i]}];
      i++;
    }
    return this.dataSource;
  }

  getProviderBenefits(plan) {
    let benefitsValues = [
      plan.hospitals[0].value,
      plan.hospitals[1] ? plan.hospitals[1].value : 'N/A',
      plan.recommendedPlanDetails.excess,
      plan.hospitals[2] ? plan.hospitals[2].value : 'N/A',
      plan.hospitals[3] ? plan.hospitals[3].value : 'N/A',
      plan.hospitals[4] ? plan.hospitals[4].value : 'N/A',
      plan.hospitals[5] ? plan.hospitals[5].value : 'N/A'
    ];
    plan.benefitCategories.forEach(category => benefitsValues = [...benefitsValues, category.benefitDTO.value]);
    return benefitsValues;
  }

  getRowIndexesToHighlight(data) {
    let selectedIndexes = [];
    this.selectedByUser = [];
    data.benefitCategories.forEach(category => {
      if (category.userSelectedCategory === true) {
        this.selectedByUser = [...this.selectedByUser, category.categoryDTO.name.toLowerCase()];
      }
    });
    this.rowLabels.forEach(row => {
      const i = this.rowLabels.indexOf(row);
      if (this.selectedByUser.includes(row.toLowerCase())) {
        selectedIndexes = [...selectedIndexes, i];
      }
    });
    return selectedIndexes;
  }
}
